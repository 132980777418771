body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3c4858;
  zoom: 80% !important;
}

:root {
  --theme: #0a4c96;
  --contrast: #eb5156;
  --success: #496b28;
  --err: #ffffff;
  --redBtnCart: #fc0101 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

section {
  padding: 30px 0 30px 0;
  position: relative;
  overflow: hidden;
  text-align: justify;
}

.section-0 {
  padding-top: 90px;
}

.section-title {
  text-align: center;
  padding: 20px;
}

.ui-el-type-entity .ui-overlay,
.ui-el-type-list .ui-overlay {
  top: 65px !important;
}

.section-odd {
  background: #888;
  color: #fff;
}

.ui-col {
  display: inline-block;
  width: 100%;
}

.ui-table > .ui-col {
  display: table-cell;
}

.ui-col-6 {
  width: 50%;
}

.ui-grid-row {
  display: table-row;
}

.ui-grid {
  display: table;
  width: 100%;
}

.ui-grid-header,
.ui-grid-body {
  display: table-row-group;
}

.ui-el-grid-body {
  display: table-row-group;
}

.ui-grid-col {
  padding: 10px;
  vertical-align: middle;
  display: table-cell;
}

.ui-col-3 {
  width: 33.33%;
}

.ui-layout-card .ui-grid {
  display: block;
  border: 0;
}

.ui-layout-card .ui-grid-row {
  display: inline-flex;
}

.ui-row-even {
  background: #fff;
}

.ui-layout-card .ui-grid-col {
  margin: 10px;
  padding: 20px 15px;
  border-radius: 5px;
  display: block;
  width: 100%;
  box-shadow: 2px 2px 10px -5px rgba(0, 0, 0, 0.2);
  font-weight: 400;
  color: #555;
  border-left: 5px solid var(--theme);
}

.ui-pgto-descricao {
  margin-bottom: 10px;
  border: 1px solid #dedede;
  font-size: 20px;
  padding: 15px;
  font-weight: 500;
  background: #f5efd2;
  box-shadow: 2px 2px 10px -5px rgba(0, 0, 0, 0.2);
}

section {
  margin-top: 35px;
  background: #f5f5f5;
}

.ui-search-controls button {
  margin-right: 10px;
}

.ui-search-controls button {
  margin-right: 10px;
}

.ui-search-top > div * {
  display: inline-block;
  width: auto;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

.ui-input-search {
  padding: 5px;
}

.ui-search-top {
  display: table;
  padding: 15px 0;
  width: 100%;
}

.ui-search-top > div {
  display: table-cell;
}

.ui-search-right {
  text-align: right;
}

.ui-search-top input {
  max-width: 210px;
  margin-left: 5px;
  margin-right: 5px;
}

.ui-search-top * {
  vertical-align: middle;
}

.ui-entity {
  display: table;
  width: calc(100% - 10px);
}

.ui-entity-item {
  display: table-cell;
  vertical-align: middle;
}

.ui-entity-act {
  width: 40px;
  padding: 0 5px;
  text-align: center;
}

.ui-el-item-buttons .ui-el-item {
  display: inline-flex;
}

.ui-el-item button {
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75) !important;
  border-radius: 2px !important;
}

.ui-el-item button:hover {
  opacity: 0.7;
}

.ui-layout-table > .ui-el-item-inner > .form-label {
  width: 100%;
  padding: 10px 15px;
  margin: 15px 0;
  border: 1px solid #dedede;
  background: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 2px 15px -10px rgba(0, 0, 0, 0.75);
}

.ui-view {
  margin: 30px;
}

.ui-layout-group,
.ui-layout-buttons,
.ui-layout-tab {
  margin-bottom: 15px;
  border: 1px solid #dedede;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
  border-radius: 3px;
}

.ui-el-type-list > .ui-el-label {
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 10px;
}

.ui-group-label {
  font-weight: 400;
  padding-top: 0;
  padding-bottom: 15px;
  color: #888;
}

.ui-layout-buttons {
  padding: 15px;
  height: 40px;
}

.ui-el-item-buttons_right {
  float: right;
}

.ui-el-item {
  vertical-align: middle;
}

.ui-col-header {
  background: #f4f4f9;
  font-size: 15px;
  color: #3b3e66;
  font-weight: 400;
  white-space: nowrap;
}

.ui-grid-col.ui-col-avatar img {
  height: 70px;
  width: 70px !important;
}

input[type="checkbox"],
input[type="radio"] {
  width: auto;
}

.ui-el-item-logar {
  position: absolute;
  width: 330px;
  min-height: 430px;
  padding: 40px 20px;
  margin-top: 150px;
  left: calc(50% - 160px);
  background: #003172;
  /* border: 1px solid #fff; */
  border-radius: 25px;
  box-shadow: 5px 10px 25px 15px rgba(0, 0, 0, 0.2);
}

.ui-login-panel {
  background-image: url("/static/img/bg_login.png");
  position: fixed;
  overflow: auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  object-fit: contain;
  background-size: cover;
}

.ui-text-center {
  text-align: center;
}

.ui-center {
  text-align: center;
  margin: auto;
}

.ui-boleto .ui-button-link {
  min-width: 330px;
  display: inline-block;
}

.ui-barcode {
  font-weight: 400;
  font-size: 16px;
}

.bg-theme {
  background: var(--theme);
}

.ui-boleto i {
  font-size: 100px;
  padding-bottom: 10px;
}

.ui-text-bold {
  font-weight: 500;
}

.ui-btn-login button {
  width: calc(100% - 10px);
  margin-top: 20px;
  background: var(--contrast);
}

.ui-el-item-btnLogar button {
  width: calc(100% - 10px);
  margin-top: 20px;
  background: var(--contrast);
}

.ui-disabled i {
  color: #dedede;
}

.ui-login-panel .ui-el-item-logo {
  padding: 15px;
}

.ui-login-panel * {
  color: #f5f5f5 !important;
  border-color: currentColor !important;
  padding-bottom: 1%;
  margin-top: -1%;
}

label {
  font-weight: 400;
}

.ui-grid-controls {
  padding: 10px;
  border-top: 0;
  position: relative;
  height: 56px;
}

.ui-grid-controls-right {
  float: right;
}

.ui-grid-controls-middle {
  vertical-align: middle;
}

.ui-grid-controls * {
  display: inline-flex !important;
  width: auto !important;
  vertical-align: middle !important;
  margin-right: 5px;
  margin-left: 5px;
  height: 35px !important;
  line-height: 35px !important;
}

body,
#root {
  background: #f5f5f5;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  overflow: auto;
  color: #3c4858 !important;
}

img.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.pull-left {
  float: left;
}

.ui-col-empty {
  text-align: center;
  border-bottom: 0;
}

.form-control,
button {
  border-radius: 2px !important;
}

.avatar-name {
  padding-left: 10px;
  text-transform: lowercase;
}

.ui-click {
  cursor: pointer;
}

.dropdown-menu-right .dropdown-menu {
  right: 20px;
  width: auto;
  left: auto;
}

.navbar-dark .dropdown-menu-right .dropdown-toggle {
  color: #fff;
}

.navbar {
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
}

.ui-el-item-entity {
  vertical-align: top;
}

.ui-overlay {
  position: fixed;
  background: #000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.6;
  z-index: 999;
}

.ui-dialog-header {
  padding: 15px;
  font-weight: 400;
  height: 55px;
  border-bottom: 1px solid #dedede;
}

.ui-dialog {
  position: fixed;
  max-width: calc(100% - 170px);
  margin: auto;
  top: 90px;
  background: #fff;
  left: 0;
  z-index: 9998;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  bottom: 20px;
}

.ui-tab-icon {
  padding-right: 10px;
}

.navbar-dark a.ui-tab-sel.nav-link {
  font-weight: 400;
  opacity: 1;
  color: #fff !important;
}

.ui-col-1 {
  width: 8.33%;
}

.ui-col-2 {
  width: 16.66%;
}

.ui-col-3 {
  width: 24.99%;
}

.ui-col-4 {
  width: 33.32%;
}

.ui-col-5 {
  width: 41.65%;
}

.ui-col-6 {
  width: 49.98%;
}

.ui-col-7 {
  width: 58.31%;
}

.ui-col-8 {
  width: 66.64%;
}

.ui-col-9 {
  width: 74.97%;
}

.ui-col-10 {
  width: 83.3%;
}

.ui-col-11 {
  width: 91.63%;
}

.ui-col-12 {
  width: 99.96%;
}

.btn-warning {
  color: #fff !important;
}

.ui-search-box input {
  border: 0 !important;
}

.ui-search-box {
  border: 1px solid #dedede;
  padding-right: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.ui-col-label-mobile {
  font-weight: 400;
  padding-bottom: 5px;
  display: none;
}

@media (max-width: 1280px) {
  .ui-col-1,
  .ui-col-2,
  .ui-col-3,
  .ui-col-4,
  .ui-col-5,
  .ui-col-6,
  .ui-col-7,
  .ui-col-8,
  .ui-col-9,
  .ui-col-10,
  .ui-col-11 {
    width: 50%;
  }
  .ui-col-12 {
    width: 100%;
  }
}

.ui-search-box,
.ui-search-box * {
  background: #f5f5f5;
  opacity: 0.8;
}

.ui-dialog-data {
  background: #f5f5f5;
  overflow: auto;
  padding-bottom: 20px;
  padding: 20px;
  bottom: 0;
  top: 55px;
  border-radius: 5px;
  left: 0;
  right: 0;
  position: absolute;
}

.MuiFormControl-root.MuiTextField-root {
  width: calc(100% - 10px);
}

.MuiList-padding {
  padding: 10px !important;
}

.ui-el-item {
  vertical-align: top;
}

.ui-el-type-checkbox,
.ui-el-type-complete,
.ui-el-type-select,
.ui-el-type-textarea,
.ui-el-type-date,
.ui-el-type-datetime,
.ui-el-input,
.ui-el-type-entity,
.ui-el-type-file,
.ui-el-type-avatar {
  padding-bottom: 5px;
  padding-top: 5px;
}

.ui-el-type-complete.ui-col,
.ui-el-type-checkbox.ui-col,
.ui-el-type-select.ui-col,
.ui-el-type-textarea.ui-col,
.ui-el-type-date.ui-col,
.ui-el-type-button.ui-col,
.ui-el-type-list.ui-col,
.ui-el-type-datetime.ui-col,
.ui-el-input.ui-col,
.ui-el-type-entity.ui-col,
.ui-el-type-file.ui-col,
.ui-el-type-avatar.ui-col {
  vertical-align: middle;
}

input[type="checkbox"],
input[type="radio"] {
  width: 100%;
}

.ui-search-data {
  position: relative;
  width: 100%;
}

.ui-search-data .ui-grid-row:last-child .ui-grid-col {
  border-bottom: 0 !important;
}

.ui-loading {
  text-align: center;
  vertical-align: middle;
  font-size: 30px;
}

.ui-tag {
  position: absolute;
  right: 15px;
  top: 150px;
  background: #888;
  padding: 2px;
  opacity: 0.7;
  border-radius: 5px;
}

@media (max-width: 800px) {
  .ui-tag {
    top: 10px;
  }
}

.ui-tag .ui-el-item-inner {
  font-weight: bold;
  background: var(--theme);
  padding: 5px 15px;
  color: #fff;
  border-radius: 5px;
  font-size: 10px;
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
  display: block;
}

a {
  cursor: pointer;
}

.ui-loading-button i {
  margin-right: 10px;
}

.ui-search-label {
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 10px;
}

.ui-layout-tab .ui-layout-item {
  display: inline-flex;
  padding: 10px;
  padding-top: 15px;
  font-weight: 400;
  border-bottom: 5px solid #fff;
  border-radius: 0;
  cursor: pointer;
}

.ui-layout-tab {
  padding-bottom: 0;
  padding-left: 10px;
  padding-top: 0;
}

.ui-tab-sel {
  font-weight: 400;
}

.border-color {
  border-color: var(--contrast) !important;
}
.ui-el-type-checkbox.ui-error .ui-el-item-inner,
.ui-el-type-checkbox.ui-error .ui-el-item-inner *,
.ui-error.ui-el-input .MuiFormControl-root *,
.ui-error.ui-el-type-date .MuiFormControl-root *,
.ui-error.ui-el-type-datetime .MuiFormControl-root *,
.ui-el-type-select.ui-error .MuiFormControl-root *,
.ui-el-type-entity.ui-error .MuiFormControl-root * {
  color: var(--contrast) !important;
  border-color: var(--contrast) !important;
}

.ui-dialog-data .ui-col {
  min-width: 49.99%;
}

.ui-view-home .ui-col.ui-col-3.ui-row-even {
  background: transparent;
}

.ui-view-home .ui-grid-row > .ui-grid-col {
  background: #fff;
}

.menu-left {
  position: fixed;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  bottom: 0;
  left: 0;
  box-shadow: 8px 2px 15px -12px rgba(0, 0, 0, 0.75);
  width: 100px;
  z-index: 999;
}

.view-data {
  margin-left: 100px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  overflow: auto;
  bottom: 0;
  background: #f5f5f5;
  padding-bottom: 100px;
}

.navbar.fixed-top {
  left: 100px;
}

.menu-lateral .ui-row-even {
  background: transparent;
  color: #fff !important;
}

.menu-lateral .ui-grid-row {
  color: #fff !important;
}

.menu-lateral * {
  border: 0 !important;
}

.menu-left .ui-grid-row,
.menu-left .ui-grid-col,
.menu-left .ui-grid {
  width: 100px;
  display: block;
  text-align: center;
}

.menu-left img {
  width: 70px;
  margin: 15px !important;
  text-align: center;
  background: #0A4C96;
  border-radius: 50%;
}

.menu-left .ui-col-icone {
  font-size: 25px;
  padding-bottom: 0;
}

.menu-lateral .ui-col-icon {
  padding-bottom: 0;
}

body * {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.ui-col-remove {
  width: 40px;
  text-align: center;
}

.ui-el-item-file label {
  transform: translate(14px, -6px) scale(0.75) !important;
  background: #fff;
}

.ui-layout-card .ui-search-data {
  box-shadow: none;
  border: 0;
}

.ui-col-sort {
  padding-left: 10px;
  cursor: pointer;
}

.menu-lateral .ui-search-data {
  box-shadow: none;
  overflow: hidden;
}

.ui-filter-item {
  width: 33.33%;
  display: inline-block;
  padding: 5px 0;
}

.ui-search-filters {
  /* width: 39.9%; */
  border: 1px solid #dedede;
  padding: 10px;
  border-bottom: 0;
}

.menu-lateral .ui-loading {
  color: #fff;
}

.dlg-msg-error {
  padding-bottom: 5px;
  color: var(--contrast);
}

.ui-dlg-close {
  cursor: pointer;
  position: absolute;
  float: right;
  right: 15px;
  top: 15px;
}

.ui-el-avatar {
  width: 70px;
  border-radius: 50%;
  height: 70px;
  margin-right: 15px;
}

.ui-main-menu .ui-row-even {
  background: transparent;
}

.ui-main-menu .ui-grid-col {
  background: #fff;
}

.ui-el-type-list .ui-item-label {
  font-weight: 400;
  margin-bottom: 10px;
  display: inline-block;
  padding-right: 10px;
}

.ui-grid-col img {
  max-height: 60px;
}

.ui-el-item-comprar,
.ui-el-item-comprar * {
  text-align: left !important;
  justify-content: initial;
}

.ui-el-type-link a {
  padding: 10px;
  display: block;
}

.ui-layout-group .ui-el-type-list .ui-search {
  border: 1px solid #dedede;
  padding: 10px 15px;
  border-radius: 5px;
  background: #f5f5f5;
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
}

.ui-layout-group .ui-search-data {
  background: #fff;
}

.ui-el-type-checkbox:not(.ui-el-column) .ui-el-item-inner {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  text-align: left;
  vertical-align: middle;
  padding-bottom: 0;
  min-height: 50px;
  line-height: 55px;
  margin-right: 10px;
}

.ui-el-type-checkbox .MuiFormControlLabel-label {
  color: #555;
  font-size: 15px;
}

.ui-layout-buttons {
  background: transparent;
  border: 0;
  box-shadow: none;
  padding: 0;
}

.ui-el-type-button.ui-theme-default button {
  border: 1px solid var(--theme);
  color: var(--theme);
}

.ui-tree-commands button {
  margin-right: 15px;
}

.ui-tree-left,
.ui-tree-right {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.ui-tree-data {
  vertical-align: top;
  padding: 15px;
}

.padding-bottom {
  padding-bottom: 10px;
}

.margin-top {
  margin-top: 10px;
}

.ui-catalogo-item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  vertical-align: top;
}

.ui-catalogo-itens {
  display: flex;
}

.ui-catalogo-item-0 .ui-catalogo-label {
  width: max-content;
}

.ui-catalogo-item-0 > .ui-catalogo-itens {
  border: 1px solid #dedede;
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
  display: none;
  position: fixed;
  z-index: 9999;
  overflow: auto;
}

/*@media (max-width: 800px){
  .ui-catalogo-item-0>.ui-catalogo-itens{
    position: inherit;
  }
  .ui-catalogo-item{
    display:block;
  }
}



*/

.ui-catalogo-item:hover > .ui-catalogo-itens {
  display: flex;
}

.ui-el-item-buttons_right .ui-el-type-button {
  margin-left: 15px;
  height: 55px;
}

.ui-el-item-buttons_left .ui-el-type-button {
  margin-right: 15px;
}

.ui-catalogo-item-0:hover {
  position: relative;
}

.ui-catalogo-top {
  background: #ffff;
  text-align: center;
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
  padding: 5px;
  left: 0;
  right: 0;
  z-index: 10;
}

.ui-catalogo-itens {
  background: #fff;
  padding: 5px;
}

.ui-catalogo-label {
  padding: 10px 15px;
}

.ui-catalogo-item-0 .ui-catalogo-label,
.ui-catalogo-item-1 .ui-catalogo-label {
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: 4px solid transparent;
}

.ui-catalogo-item-0 > .ui-catalogo-label {
  font-weight: 500;
}

.ui-catalogo-item-0 > .ui-catalogo-label:hover {
  border-color: tomato;
}

.ui-catalogo-banner img {
  width: 100px;
  max-height: 500px;
  object-fit: cover;
}

.ui-catalogo-item-2 .ui-catalogo-label {
  font-weight: normal;
  padding: 5px;
  border: 0;
}

.ui-catalogo-itens .ui-layout-list {
  width: 100%;
}

.ui-layout-list .ui-grid-col-row,
.ui-layout-list .ui-search-data {
  border: 0;
}

.ui-layout-carousel .ui-grid-col-row,
.ui-layout-list .ui-grid-col-row {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

.ui-row-card .ui-col-element {
  overflow: hidden;
  background: #fff;
  height: 450px;
  margin: 15px;
  padding: 0;
  box-shadow: 0px 2px 10px -12px rgba(0, 0, 0, 0.75);
}

.ui-card-produto .ui-el-avatar {
  max-height: 180px;
  width: auto;
  border-radius: 0 !important;
  font-weight: normal !important;
  height: 180px;
}

.ui-button-link {
  background: var(--theme);
  padding: 10px 30px;
  color: #fff !important;
  border-radius: 3px;
}

.ui-button-link:hover {
  opacity: 0.7;
  text-decoration: none;
}

.ui-col-element {
  position: relative;
}

.ui-el-item-destaque .ui-el-item-inner {
  display: inline-block;
  padding: 5px 10px;
  background: #dedede;
  color: #fff;
  border-radius: 5px;
  z-index: 9;
  position: absolute !important;
  left: 0;
  box-shadow: 0px 2px 10px -12px rgba(0, 0, 0, 0.75);
}

.ui-grid-row.ui-click:hover .ui-grid-col {
  background: #f9efd2;
}

.menu-lateral .ui-grid-row.ui-click:hover .ui-grid-col {
  background: #2991ff;
}

.tab-item {
  position: relative;
}

.tab-close {
  padding: 10px;
  right: 0;
  position: absolute;
  top: -20px;
  border-radius: 50%;
  opacity: 1;
  display: none;
  font-size: 14px;
}

.tab-item:hover .tab-close {
  display: inline;
}

.ui-cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #ed343a;
}

.ui-cookie-bar .ui-el-item-right .ui-el-item {
  display: inline-block !important;
  width: 115px;
}

.ui-cookie-bar * {
  color: #fff;
  vertical-align: middle;
}

.ui-cookie-bar button {
  background: #ed343a;
  border: 1px solid #fff;
  box-shadow: none;
}

.ui-cookie-bar .ui-el-item-left {
  line-height: 15px;
  float: left;
  margin: 15px;
}

.ui-cookie-bar .ui-el-item-right {
  width: 300px;
  float: right;
  margin: 15px;
}

.ui-cookie-bar .ui-el-item-overlay {
  position: fixed;
  background: #fff;
  top: 0;
  bottom: 70px;
  left: 0;
  right: 0;
  opacity: 0.7;
}

.ui-card-produto .ui-el-item-comandos .ui-el-item,
.ui-card-produto .ui-el-item-comandos .ui-el-item-inner {
  margin: 0 !important;
  padding: 0 !important;
}

.ui-card-produto .ui-el-item-comandos button {
  width: 100%;
  margin: 0 !important;
}

.ui-card-produto .ui-el-item-comprar i {
  padding-right: 10px;
}

.ui-card-produto .ui-el-item-comandos {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
}

.ui-card-produto .ui-el-item-comprar button:hover {
  background: var(--theme) !important;
  color: #fff !important;
  border: 0 !important;
}

.ui-card-produto .ui-el-item-descricao {
  padding: 0 10px;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  padding-bottom: 10px;
}

.ui-layout-carousel > .ui-el-label {
  font-size: 24px;
  padding-left: 45px;
}

.ui-card-produto .ui-el-item-valor {
  text-align: left;
  padding-left: 10px;
  font-size: 20px;
}
.ui-layout-carousel .ui-grid {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  height: 485px !important;
}

.ui-search-nav-left,
.ui-search-nav-right {
  position: absolute;
  cursor: pointer;
  color: var(--theme);
  bottom: 0;
  width: 30px;
  top: 15px;
  vertical-align: middle;
  line-height: 380px;
  font-size: 40px;
  background: transparent;
  border-radius: 5px;
  padding: 5px;
}

.ui-layout-carousel {
  position: relative;
}

.ui-layout-carousel .ui-search {
  position: absolute;
  width: 100%;
}

.ui-layout-carousel .ui-search,
.ui-layout-carousel,
.ui-layout-carousel .ui-search-data,
.ui-layout-carousel .ui-grid {
  height: 470px;
}

.ui-catalogo-carousel .ui-layout-carousel {
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 15px;
}

.ui-carousel-small.ui-layout-carousel .ui-search,
.ui-carousel-small.ui-layout-carousel,
.ui-carousel-small.ui-layout-carousel .ui-search-data,
.ui-carousel-small.ui-layout-carousel .ui-grid {
  height: 100px !important;
  overflow: hidden;
}

.ui-carousel-small .ui-search-nav-left,
.ui-carousel-small .ui-search-nav-right {
  line-height: 60px;
  font-size: 20px;
  color: #000;
  bottom: 30px;
  width: 20px;
  top: 5px;
}

.ui-layout-carousel .ui-grid-col {
  border-bottom: 0;
}

.ui-layout-carousel .ui-search-data {
  position: absolute;
  left: 30px;
  width: auto;
  border: 0;
  overflow: hidden;
  right: 30px;
}

.ui-row-card.ui-card-produto {
  background: transparent;
}

.ui-search-nav-left {
  left: 0;
}

.ui-search-nav-right {
  right: 0;
}

.ui-layout-carousel .ui-grid-row {
  flex-shrink: 0;
  background: transparent;
}

.ui-grid-scrolling {
  opacity: 0.5;
}

.ui-carousel-label {
  font-weight: 400;
  padding-top: 15px;
  font-size: 20px;
}

.product-detail .ui-el-item-right {
  position: relative;
}

.product-detail .ui-card-produto .ui-el-item-descricao {
  padding: 0;
}

.product-detail {
  padding-top: 20px;
  margin: auto;
  max-width: 1400px;
}

.product-detail .ui-el-avatar {
  width: auto;
  height: auto;
  border-radius: 0;
  max-width: 100%;
}

.product-detail .ui-el-item-destaque .ui-el-item-inner {
  position: relative !important;
}

.navbar-nav {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-item {
  flex-shrink: 0;
}

.ui-el-item-comandos .ui-el-item-inner {
  display: table;
  width: 100%;
}

.ui-el-item-comandos .ui-el-item-inner .ui-col {
  width: auto;
  display: table-cell !important;
  padding: 2px !important;
}

.ui-el-item-favorito {
  width: 60px !important;
}

::-webkit-scrollbar-track {
  background-color: #dedede;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #dedede;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--theme);
}

.tab-item * {
  color: #555;
  vertical-align: middle;
}

.tab-item:hover {
  opacity: 0.7;
}

@media (min-width: 900px) {
  .navbar-expand-lg .navbar-collapse {
    max-width: 100%;
  }
  .product-detail .ui-el-item-left > .ui-el-item-inner {
    position: relative;
    margin: 0 30px;
  }
}

.info-tabs {
  margin-top: 20px;
}

.info-tabs .ui-item-label {
  font-weight: 400;
  padding-left: 10px;
}

.info-tabs .ui-el-type-card > .ui-el-item-inner {
  border: 1px solid #dedede;
  margin: 5px;
  padding: 10px;
  background: transparent;
  min-height: 190px;
  border-radius: 10px;
}

.ui-catalogo-content {
  color: #555;
  padding-bottom: 30px;
  margin-top: 160px;
}

.ui-el-item-valores * {
  vertical-align: middle;
}

.product-detail .ui-card-produto .ui-el-item-comandos {
  position: relative;
}

.ui-gallery-preview .ui-el-avatar {
  max-height: 400px;
  margin: auto;
  text-align: center;
}

.ui-gallery-preview * {
  margin: auto;
  display: block;
}

.ui-layout-carousel .ui-el-item-inner {
  position: relative;
}

.ui-padding {
  padding: 15px;
}

.ui-off-padding-left {
  padding-left: 0;
}

.ui-off-padding-right {
  padding-left: 0;
}

.ui-big-label {
  padding-left: 0;
  padding-right: 0;
  font-size: 25px;
  color: #555555;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: left;
  line-height: 30px;
  font-weight: 300 !important;
}

.ui-resumo-pedido-label {
  padding-left: 0px;
  padding-right: 0px;
  font-size: 25px;
  color: #555555;
  /* margin-top: 50px; */
  text-align: left;
  line-height: 10px;
  /* font-weight: 200 !important; */
}

.ui-radius {
  border-radius: 3px;
}

.ui-gray {
  background: #f5f5f5;
}

.ui-white {
  background: #fff;
}

.ui-shadow {
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
}

.ui-border {
  border: 1px solid #dedede;
}

.ui-border-bottom {
  border-bottom: 1px solid #dedede;
}

.ui-border-top {
  border-top: 1px solid #dedede;
}

.ui-border-left {
  border-left: 1px solid #dedede;
}

.ui-color-green {
  color: #429801;
}

.ui-color-red {
  color: var(--contrast);
}

.ui-color-blue {
  color: var(--theme);
}

.ui-opacity {
  opacity: 0.7;
}

.ui-bold {
  font-weight: 400;
}

.ui-item-select {
  border-bottom: 4px solid var(--contrast) !important;
}

.ui-pagamento {
  text-align: left;
  color: #555555;
  background: #fff;
}

.ui-tabs .ui-col {
  cursor: pointer !important;
  border-bottom: 4px solid #7c9dc1;
  line-height: 42px;
}

.ui-content {
  padding: 0 30px;
}

.ui-bandeira i {
  font-size: 60px;
}

.ui-bandeira {
  cursor: pointer;
  padding: 20px 20px;
}

.ui-bandeiras {
  text-align: center;
  border-bottom: 1px solid #dedede;
  padding-bottom: 15px;
}

.ui-col.ui-pgto-status {
  width: 80px !important;
  background: var(--contrast) !important;
  text-align: center;
  color: #fff;
  font-size: 25px;
}

.ui-col.ui-pgto-status.ui-pgto-status-confirmado {
  background: #429801 !important;
}
.ui-check-icon {
  padding: 10px;
  color: var(--theme);
  font-size: 20px !important;
}

.ui-col {
  vertical-align: top;
}

.ui-pointer {
  cursor: pointer;
}

.ui-row-odd {
  background: #f9fafe;
}

.ui-small-label {
  color: #555555;
  font-size: 14px;
  font-weight: 400;
}

.ui-small {
  font-size: 14px;
}

.ui-content {
  /**
  font-size: 14px;
**/
  color: #555;
}

.ui-middle,
.ui-middle * {
  vertical-align: middle;
}

.ui-small-button button {
  min-height: 55px;
}

.menu-lateral .ui-row-odd {
  background: transparent;
}

.ui-toggle i {
  font-size: 30px;
  color: var(--theme);
}

.ui-toggle {
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
}

.ui-toggle * {
  vertical-align: middle;
}

.ui-toggle-label {
  padding-left: 10px;
  font-weight: 400;
}

.MuiMenu-list {
  cursor: pointer;
}

.ui-el-type-input.ui-disabled,
.ui-el-type-button.ui-disabled {
  opacity: 0.6;
}

.ui-layout-tab .ui-tab-sel {
  border-color: var(--contrast) !important;
}

.ui-toggle.ui-disabled {
  opacity: 0.7;
  cursor: auto;
}

.ui-big-icon {
  font-size: 70px;
}

.ui-finalizar {
  padding-top: 15px;
  height: 70px;
}

.ui-btn-orcamento {
  float: right;
  padding-bottom: 10px;
}

.ui-finalizar .ui-el-item-finalizar {
  float: right;
}

.ui-finalizar .ui-el-item-finalizar * {
  color: green;
  border-color: green !important;
}

.ui-layout-inline > .ui-el-item-inner > .ui-el-item {
  display: inline-block;
  width: auto;
}

.ui-spinner * {
  padding: 4px;
  display: table-cell;
}

.ui-spinner-value {
  text-align: center;
  border: 0;
  width: 80px;
}

.ui-spinner {
  background: #fff;
  display: table;
  padding: 0 10px;
  border: 1px solid;
  border-radius: 20px;
}

.ui-theme-success button {
  background: green;
  color: #fff;
}

.ui-col > .ui-el-type-entity {
  vertical-align: middle;
  display: inline;
}

.ui-card-title {
  border-bottom: 1px solid #dedede;
  padding: 15px;
  font-weight: 400;
}

.ui-card-data {
  padding: 15px;
}

.ui-theme .ui-card-title {
  background: var(--theme);
  color: #fff;
  border: 0;
}

.ui-card-resumo .ui-card-data .ui-small-label,
.ui-card-resumo .ui-card-data .ui-value {
  display: inline-block;
  width: 50%;
}

.ui-card-resumo .ui-card-data .ui-value {
  text-align: right;
}

.ui-card-resumo .ui-card-data .ui-col {
  display: block;
  width: 100%;
  border-bottom: 1px solid #dedede;
}

.ui-view-carrinho .ui-input-button button {
  width: 40px;
  height: 45px;
}

.ui-view-carrinho .ui-entity-item button {
  height: 45px;
  border: 1px solid var(--theme);
  color: var(--theme);
  background: transparent;
}

.ui-el-type-complete .MuiInputLabel-outlined {
  left: 5px;
  top: -2px;
}

.ui-parcela .ui-col {
  display: inline-block;
  width: auto;
  padding-right: 15px;
}

.ui-float-left {
  float: right;
}

.ui-fix-right {
  padding-top: 10px;
  padding-right: 10px;
}

.ui-padding-left {
  padding-left: 15px;
}

.ui-forma-entrega {
  padding: 15px 20px;
  border-bottom: 1px solid #dedede;
}

.ui-timeline .ui-col {
  width: calc(100% / 5);
  text-align: center;
  position: relative;
}

.ui-tl-label {
  font-weight: 400;
  top: 70px;
  position: absolute;
  left: 0;
  right: 0;
}

.ui-tl-date {
  font-size: 12px;
  height: 20px;
}

.ui-tl-icon {
  z-index: 999999;
  font-size: 25px;
  position: absolute;
  left: calc(50% - 15px);
  background: #fff;
}

.ui-tl-bar {
  position: absolute;
  height: 4px;
  background: #888;
  left: 0;
  right: 0;
  top: 50px;
}

.ui-tl-item {
  height: 140px;
}

.ui-timeline {
  border: 1px solid #dedede;
  border-radius: 10px;
}

.ui-bloco-entrega {
  padding-right: 20px;
}

.ui-tl-sel i {
  color: green;
}

.ui-view.ui-view-carrinho {
  margin: 0;
}

.ui-view.ui-view-carrinho.ui-bloco-entrega {
  padding-right: 20px;
}

.ui-content {
  padding: 40px;
}

.ui-topo {
  height: 70px;
  width: 100%;
  vertical-align: middle;
}

.ui-topo > .ui-col {
  line-height: 35px;
}

.ui-catalogo-data,
.ui-carousel-label {
  margin-right: 80px;
  margin-left: 80px;
}

.ui-catalogo-top .MuiOutlinedInput-root {
  border-radius: 20px;
}

.ui-catalogo-top .ui-catalogo-item-0 {
  margin-top: 10px;
}

.ui-layout-carousel-vertical {
  width: 85px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-bottom: 0;
}

.ui-layout-carousel-vertical .ui-search-data {
  position: absolute;
  width: 82px;
  top: 25px;
  bottom: 15px;
  overflow: hidden;
}

.ui-el-item-zoom_overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  opacity: 0.5;
  z-index: 1;
}

.ui-el-item-zoomarea {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.ui-el-item-zoom_data {
  position: absolute;
  background: #fff;
  z-index: 2;
  margin: auto;
  text-align: center;
  right: 50px;
  left: 50px;
  top: 50px;
}

.ui-el-item-lnk_avatar {
  cursor: pointer;
}

.ui-el-item-zoom_close {
  position: absolute;
  top: 70px;
  right: 70px;
  z-index: 999;
  padding: 0;
  font-size: 20px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 35px;
  text-align: center;
}

.ui-el-item-zoom_close * {
  color: #000;
  font-size: 30px;
}

.ui-carousel-vertical .ui-search-nav-right,
.ui-carousel-vertical .ui-search-nav-left {
  top: auto;
  width: calc(100% - 3px);
  bottom: auto;
  text-align: center;
  height: 25px;
  padding: 0;
  left: 0;
  border-radius: 0;
  vertical-align: middle;
}

.ui-carousel-vertical i {
  display: inherit;
  padding-top: 3px;
}

.ui-carousel-vertical .ui-search-nav-left {
  top: 0;
}

.ui-carousel-vertical .ui-search-nav-right {
  bottom: 0;
}

.product-detail .ui-el-item-left {
  position: relative;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    height: 65px;
  }
}
.tab-item {
  border-right: 1px solid #dedede;
  padding: 0 15px !important;
  line-height: 65px;
  color: #555;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui-el-type-card .ui-el-label {
  font-weight: 400;
  padding-left: 10px;
}
.ui-el-type-card .ui-el-label > div {
  display: none;
}

.menu-lateral i {
  font-size: 25px;
}

.MuiDialogTitle-root {
  color: var(--theme) !important;
}

.tab-item-click {
  padding: 15px 0;
}

@media (min-width: 990px) {
  nav.navbar.navbar-expand-lg.navbar-light.bg-white.fixed-top {
    padding: 0;
    height: 65px;
  }
  .MuiDialogActions-root {
    justify-content: center !important;
    padding-bottom: 15px !important;
  }
}

.ui-item-dialog-title {
  color: var(--theme) !important;
  font-weight: 400;
  font-size: 20px;
  padding: 10px;
}

.MuiButton-containedSecondary {
  background-color: var(--contrast) !important;
}

.ui-favoritos .ui-el-item-produtos .ui-item-label {
  padding-left: 20px;
  font-weight: 400;
}

.ui-favoritos .ui-el-item-produtos {
  padding-top: 20px;
}

.ui-catalogo-actions {
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
}

.ui-catalogo-actions .ui-el-item {
  display: inline-flex;
}

.ui-catalogo-actions * {
  font-size: 20px;
}

.ui-col-act {
  cursor: pointer;
  color: var(--blue);
}

.ui-carousel-small .ui-item-label {
  font-size: 14px;
  margin-bottom: 0;
}

.ui-col-act i {
  padding-right: 10px;
  padding-left: 10px;
}

.ui-el-item-endereco button {
  height: 48px;
}

.ui-grid-meus-pedidos .ui-grid-col {
  background: #fff;
  border: 0;
}

.ui-meus-pedidos .ui-search {
  background: transparent;
}

.ui-meus-pedidos .ui-grid-row {
  margin-bottom: 10px;
}

.ui-meus-pedidos .ui-grid {
  border-spacing: 0 10px;
}

.ui-meus-pedidos .ui-search-data {
  border: 0;
}

.ui-meus-pedidos .ui-el-type-list .ui-item-label {
  font-size: 22px;
  color: #555;
}

.ui-col-status-ped {
  border-left: 5px solid #dedede !important;
}

.ui-col-status-ped.ui-col-APROVADO {
  border-left: 5px solid green !important;
}

.ui-col-CANCELADO {
  border-color: var(--contrast) !important;
}

.ui-col-AGUARDANDO {
  border-color: orangered !important;
}
.ui-col-AGUARDANDO_PAGAMENTO {
  border-color: orangered !important;
}
.ui-el-column .ui-el-label {
  font-weight: 400;
  color: #555;
}

.ui-meus-pedidos .ui-el-column .ui-el-item-inner {
  color: #05448b;
  font-weight: 400;
}

.ui-meus-pedidos .ui-grid-row {
  vertical-align: middle;
}

.MuiDialog-paper {
  min-width: 300px;
}

.ui-row-APROVADO .ui-col-descricao_status .ui-el-column .ui-el-item-inner {
  color: green !important;
}

.ui-row-AGUARDANDO .ui-col-descricao_status .ui-el-column .ui-el-item-inner {
  color: orangered !important;
}

.ui-row-CANCELADO .ui-col-descricao_status .ui-el-column .ui-el-item-inner {
  color: var(--contrast);
}

.ui-tl-erro {
  color: var(--contrast);
}

.ui-tl-erro .ui-tl-bar {
  background: var(--contrast);
}

.ui-not {
  position: relative;
  margin-right: 15px;
}

.ui-not-total {
  position: absolute;
  top: -15px;
  right: -10px;
  background: var(--contrast);
  width: 20px;
  height: 20px;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  border-radius: 50%;
}

.ui-not a {
  padding: 5px;
  color: var(--theme);
}

.ui-el-item-numero_pedido .ui-el-item-inner {
  font-weight: normal !important;
  color: #555 !important;
  vertical-align: middle;
  display: inherit;
}

.ui-el-type-tabItem > .ui-el-label {
  display: none;
}

.ui-box-dark {
  background: #dedede;
  border-radius: 10px;
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
}

.ui-card {
  background: #fff;
  padding: 15px;
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
}

.ui-promo-item {
  height: 200px;
}

.ui-promocao-data {
  display: table;
  width: 100%;
  padding: 10px;
}

.ui-promocao-data > div {
  display: table-cell;
  vertical-align: top;
  padding: 10px;
}

.ui-promo-norequired .ui-promo-item {
  display: block;
  margin-bottom: 10px;
}

.ui-promo-resume .ui-card,
.ui-promo-required,
.ui-promo-1 .ui-card {
  width: 100%;
  height: 410px;
}

.ui-promo-description {
  font-weight: 400;
}

.ui-promo-bloco .ui-fix-bottom {
  background: #fff;
}

.ui-promo-data {
  overflow: auto;
  height: 165px;
  padding-right: 10px;
}

.ui-col-type-upload {
  text-align: center;
}

.ui-promo-bloco {
  width: 25%;
}

.ui-promo-avatar img {
  max-height: 260px;
  margin: auto;
  text-align: center;
}

.ui-promo-avatar {
  margin: auto;
  text-align: center;
}

.ui-promo-bloco.ui-promo-centro {
  width: calc(50% - 45px);
}

.ui-price {
  color: #04b90a;
  font-weight: 400;
  font-size: 20px;
  padding-bottom: 10px;
  padding-top: 5px;
}

.ui-promo-item {
  position: relative;
}

.ui-fix-bottom {
  position: absolute !important;
  bottom: 0;
  right: 0;
  left: 0;
}

.ui-promo-centro {
  display: table;
  width: 100%;
}

.ui-promo-centro .ui-card {
  display: table;
  width: 100%;
  padding: 0;
}

.ui-promo-centro .ui-card > div {
  display: table-cell;
  vertical-align: top;
  padding: 15px;
}

.ui-promo-centro .ui-promo-avatar img {
  width: 130px;
}

.ui-promo-bloco.ui-promo-plus {
  vertical-align: middle;
  text-align: center;
  color: var(--red);
  width: 45px;
  font-size: 20px;
}

.ui-promo-centro .ui-promo-avatar {
  border-right: 1px solid #dedede;
  width: 150px;
}

.ui-promocao {
  margin-top: 20px;
}

.ui-promocao-label {
  font-weight: 400;
  font-size: 22px;
  padding-top: 20px;
}

.ui-medium-font {
  font-size: 20px;
}

.ui-promo-tag {
  display: inline-block;
  padding: 5px 10px;
  background: #ccc;
  color: #fff;
  font-weight: 400;
  border-radius: 5px;
}

.ui-row {
  display: table-row;
}

.ui-row > .ui-col {
  display: table-cell;
}

.ui-promo-resume-item .ui-col {
  border-bottom: 1px solid #dedede;
  padding: 10px;
  width: auto;
}

.ui-promo-resume-item-price {
  font-size: 14px;
  width: 100px !important;
}

.ui-col.ui-promo-resume-item-description {
  padding-left: 0;
}

.ui-col.ui-promo-resume-item-price {
  text-align: right;
  padding-right: 0;
}

.ui-w100 {
  width: 100%;
}

.ui-label-icon {
  padding-left: 10px;
}

.ui-promo-detail {
  position: relative;
}

.ui-promo-price.ui-price.ui-fix-bottom {
  padding-left: 15px;
}

.ui-promo-required .ui-promo-detail {
  padding-left: 20px;
  padding-right: 20px;
}

.ui-grid.ui-promo-resume-itens {
  padding-top: 10px;
}

.ui-row.ui-promo-resume-item {
  font-size: 12px;
  font-weight: 400;
}

.ui-promo-select {
  width: 20px;
  background: #f5f5f5;
  border-right: 1px solid #dedede;
}

.ui-price .ui-discount {
  color: #555 !important;
  font-size: 12px;
}

.ui-layout-report > .ui-el-label {
  display: none;
}

.ui-sql-result {
  margin: 10px;
  overflow: auto;
  border: 1px solid #dedede;
  padding: 10px;
}

.ui-sql-grid th {
  font-weight: normal;
  border: 1px solid #dedede;
  padding: 5px;
}

.ui-valor-original {
  width: 100% !important;
  text-align: left;
  padding-left: 10px;
}

.product-detail .ui-valor-original {
  padding-left: 0;
}

.ui-valor-original * {
  color: #555;
  font-size: 14px;
  padding: 0;
  text-decoration: line-through;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.ui-search-data {
  overflow: auto;
}

.ui-link-preview {
  display: block;
  margin-top: 15px;
}

.ui-link-preview img {
  max-height: 300px;
  padding: 10px;
  text-align: center;
  margin: auto;
  background-color: #dedede;
}

.ui-link-preview {
  text-align: center;
}

.ui-el-item-ir_catalogo button {
  float: right;
}

.ui-nopadding {
  padding: 0;
}

.ui-main-menu .ui-search-data {
  overflow: hidden;
}

@media (min-width: 800px) {
  .navbar-collapse.show {
    display: block;
  }
}

.ui-promo-select {
  width: 70px;
}

.ui-catalogo-data {
  padding-top: 40px;
}

.ui-el-item-sobmedida {
  position: absolute;
  right: 15px;
  top: 0;
  padding: 5px 15px;
  background: orangered;
  color: #fff;
  border-radius: 5px;
}

.ui-catalogo-label {
  text-align: left;
  width: 100% !important;
}

.ui-catalogo-sub .ui-catalogo-sub {
  padding-left: 25px;
  padding-right: 25px;
  border-left: 1px solid #dedede;
}

.ui-catalogo-item-2 > .ui-catalogo-label {
  font-weight: 400;
  color: #1e90ff;
}

.ui-col-imagemGraduacao {
  background: var(--theme);
  text-align: center;
  vertical-align: middle;
  width: 50px;
}

.ui-col-imagemGraduacao img {
  width: 50px;
}

.gr-timeline-item {
  display: table-row;
}

.gr-timeline-item > div {
  display: table-cell;
  padding: 10px;
  border-bottom: 1px solid #fff;
  position: relative;
  vertical-align: middle;
}

.gr-timeline {
  background: #e5e9ef;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  display: table;
  width: 100%;
}

.gr-timeline-avatar .gr-timeline-avatar-inner,
.gr-timeline-avatar-inner img {
  width: 45px;
  height: 45px;
}

.gr-timeline-avatar-inner img {
  padding: 10px;
  border: 0;
}

.gr-timeline-avatar-inner {
  border-radius: 50%;
  overflow: hidden;
}

.gr-timeline-avatar-inner {
  border-radius: 50%;
  overflow: hidden;
}

.gr-timeline-avatar {
  padding: 10px;
  width: 60px;
}

.gr-timeline-date {
  width: 100px;
  color: #888;
  font-size: 14px;
}

.gr-timeline-label-inner {
  background: #d2b134;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.gr-timeline-avatar-risco {
  width: 5px;
  background: #888;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
}

.gr-timeline-avatar-inner {
  position: absolute;
  bottom: 0;
  top: 0;
  margin-top: 10px;
  margin-bottom: 1px;
}

.gr-timeline-avatar {
  height: 65px;
}

.ui-card-timeline-title {
  padding-left: 10px;
  color: #888;
}

.ui-chart {
  background: #fff;
  margin: 10px;
  box-shadow: 2px 3px 10px -7px rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  overflow: hidden;
  border: 0;
}

.ui-chart-top {
  font-weight: 400;
  display: table;
  width: 100%;
  border-bottom: 1px solid #dedede;
}

.ui-chart-data {
  overflow: auto;
}

.ui-chart-top > div {
  display: table-cell;
}

.ui-chart-title {
  padding: 10px;
  line-height: 30px;
  font-weight: 400;
}

.ui-chart-actions {
  text-align: right;
  padding-right: 10px;
}

.ui-chart-actions button {
  padding: 2px 7px;
  background: #fff;
  color: var(--theme);
  margin-left: 10px;
  border: 1px solid var(--theme);
  text-transform: lowercase;
}

.ui-chart-actions {
  padding: 10px;
}

.ui-chart-filters {
  display: table;
  width: 100%;
  border-bottom: 1px solid #dedede;
  background: #fff;
}

.ui-chart-filter {
  display: table-cell;
  padding: 10px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  border-bottom: 5px solid #fff;
}

.ui-chart-filter.ui-chart-sel {
  color: var(--theme);
  border-bottom: 5px solid var(--theme);
}

.ui-chart-act.ui-chart-sel {
  color: #fff;
  background: var(--theme);
}

.ui-dashboard {
  color: #6c6c6c;
}

.ui-chart-grid .ui-chart-data {
  background: #e5ecf4;
}

.ui-chart-grid .ui-grid-row {
  background: #ffff;
}

.ui-chart-grid .ui-grid {
  border-spacing: 0 10px;
}

.ui-chart-grid .ui-search-data {
  border: 0;
}

.ui-chart-grid .ui-grid-row .ui-grid-col:first-child {
  border-left: 5px solid orange;
}

.ui-chart-header {
  color: #fff;
}

.ui-chart-header .ui-chart-inner .ui-col {
  padding: 20px;
  line-height: 45px;
  background: var(--theme);
}

.ui-chart-inner .ui-col {
  vertical-align: top;
}

.ui-chart-destaque {
  display: inline-block;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 30px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
}

.ui-chart-label {
  font-weight: 400;
  padding-left: 15px;
}

.ui-chart-valor {
  border: 1px solid #fff;
  padding: 10px 20px;
  border-radius: 20px;
}

.ui-chart-graduacao {
  background: #43c6ff !important;
}

.ui-chart-destaque img {
  width: 15px;
  vertical-align: top;
  margin-top: -4px;
}

.ui-fluxo-total {
  background: var(--theme) !important;
  color: #fff !important;
  text-align: center;
  border-radius: 2px;
  font-weight: 400;
}

.ui-fluxo-row .ui-fluxo-descricao {
  background: #fff;
  border: 0;
}

.ui-fluxo-row .ui-search-data {
  border: 0 !important;
}

.ui-fluxo-row {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  line-height: 35px;
  background: transparent;
}

.ui-fluxo-row > div {
  display: inline-block;
  vertical-align: top;
}

.ui-fluxo-data .ui-grid-row.ui-grid-sub {
  display: block;
  background: #fff;
  margin-bottom: 15px;
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
}

.ui-fluxo-data .ui-grid-row.ui-grid-sub > .ui-grid-col {
  display: block;
  padding: 0;
}

.ui-fluxo-total {
  width: 100px;
}

.ui-fluxo-expansion {
  width: 60px;
  padding: 0;
}

.ui-fluxo-expansion button {
  height: 55px;
  border-radius: 0;
}

.ui-fluxo-descricao {
  width: calc(100% - 165px);
  height: 55px;
  overflow: hidden;
}

.ui-grid-sub .ui-search-data {
  border-top: 1px solid #dedede;
}

.ui-grid-sub .ui-el-type-list > .ui-el-label {
  border-bottom: 1px solid #dedede;
  padding: 15px;
}

.ui-grid-sub .ui-search-top {
  background: #dedede;
}

.ui-opt-item > div {
  text-align: -webkit-match-parent;
  display: inline-block;
  padding: 5px;
}

.ui-opt-item {
  cursor: pointer;
}

.ui-fluxo-data .ui-col-detalhar {
  text-align: center;
  color: #fff;
  vertical-align: middle;
  background: var(--theme) !important;
}

.ui-fluxo-expansion div {
  height: 55px;
  vertical-align: middle;
  line-height: 55px;
}

.ui-filter-item .ui-el-label {
  font-size: 10px;
  z-index: 999;
  color: #555;
  margin-left: 5px;
}

.ui-filter-item {
  width: 50%;
  vertical-align: top;
  position: relative;
}

.ui-filter-date input {
  line-height: 35px;
  margin-right: 14px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding-left: 14px;
  padding-top: 3px;
}

.ui-filter-date {
  z-index: 1;
}

.ui-terceiros,
.ui-bloco-entrega .ui-light {
  background: #f5faff;
  padding: 20px;
}

.ui-bloco-entrega .ui-card-data {
  padding: 0;
}

.ui-forma-entrega:last-child {
  border-bottom: 0;
}

.ui-mmn-itens {
  display: table;
  width: calc(100% - 20px);
  margin: 10px;
}

.ui-mmn-itens > .ui-mmn-item {
  display: table-cell;
  text-align: center;
}

.ui-mmn-object > div {
  display: table-cell;
  padding: 15px;
}

.ui-mmn-object {
  display: table;
  width: 230px;
  background: #fff;
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  overflow: hidden;
  margin: 20px auto;
  text-align: left;
  cursor: pointer;
}

.ui-mmn-object-detail {
  background: var(--theme);
}

.ui-mmn-item {
  position: relative;
  padding: 0 10px;
  text-align: center;
  background: #c6defd;
}

.ui-mmn-itens {
  border-top: 1px solid #fff;
}

.ui-mmn-object-avatar,
.ui-mmn-object-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.ui-mmn-object-label {
  padding-left: 0 !important;
}

.ui-mmn-object-detail {
  width: 20px;
  padding: 0 !important;
  text-align: center;
  color: #fff;
}

.ui-mmn-divisor {
  position: absolute;
  border-right: 1px solid #ffff;
  height: 20px;
  left: 50%;
}

.ui-mmn-item.ui-mni-odd {
  background: #9fc8f5;
}

.ui-mmn-data {
  overflow: auto;
  position: relative;
  left: 20px;
  right: 20px;
  top: 50px;
  bottom: 0;
}

.ui-mmn-label-inner {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 120px;
}

.ui-mmn-label-graduacao {
  font-size: 10px;
  color: #888;
  text-transform: capitalize;
}

.ui-dashboard .ui-col-avatar,
.ui-dashboard .ui-el-type-avatar,
.ui-dashboard .ui-el-type-avatar * {
  width: 40px !important;
  padding: 0 !important;
  height: 40px !important;
  line-height: 20px !important;
}

.ui-dashboard .ui-col-posicao {
  width: 55px;
  text-align: center;
  font-weight: 400;
}

.ui-dashboard .ui-col-pontos {
  text-align: right;
  font-weight: 400;
}

.ui-mmn-dialog .MuiDialogContent-root {
  padding: 0 !important;
}

.ui-dlg-info-item {
  background: #f5f5f5;
  border-bottom: 1px solid #dedede;
  padding: 5px 15px;
  text-align: left;
}

.ui-mmn-dialog b {
  color: #05264b;
}

.ui-dlg-info-header {
  display: table;
  padding: 15px;
}

.ui-dlg-info-header img,
.ui-mmn-detail-info {
  display: table-cell;
  vertical-align: middle;
}

.ui-dlg-info-header img {
  width: 50px;
}

.ui-mmn-detail-info {
  padding-left: 10px;
}

.ui-secondary-detail {
  color: #888;
  font-size: 12px;
  text-align: left;
}

.MuiButton-textPrimary {
  color: var(--theme);
}

.ui-grid-col.ui-bg-blue {
  background: #00a8d0;
  border-bottom: 0;
}

.ui-grid-col.ui-bg-blue-dark {
  background: var(--theme);
  border-bottom: 0;
}

.ui-chart-children .ui-chart {
  margin: 0;
  border: 0;
}

.ui-dashboard .ui-col-total {
  color: #fff;
  padding: 10px;
  width: 50px;
  height: 55px;
  text-align: center;
  font-weight: 400;
}

.ui-totais-label {
  padding-left: 10px;
}

.ui-chart-totais i {
  width: 20px;
}
.ui-view-loading {
  z-index: 999999;
}

.ui-view-loading,
.ui-view-loading-overlay,
.ui-view-loading-data {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ui-view-loading-overlay {
  z-index: 9998;
  background: #000;
  opacity: 0.3;
}

.ui-view-loading-data {
  z-index: 9999;
  top: 35%;
  font-size: 50px;
}

.ui-view-loading-data,
.ui-loading {
  color: var(--theme);
}

.ui-form-pgto .ui-el-type-button button {
  line-height: 36px;
  width: calc(100% - 10px);
}

.ui-el-item-filiais_estoques .ui-search {
  background: #fff;
}

.ui-el-item-filiais_estoques {
  margin-top: 20px;
}

.ui-el-item-dimensoes .ui-el-type-number > .ui-el-item-inner {
  display: table;
  width: 100%;
}

.ui-el-item-dimensoes .ui-el-type-number > .ui-el-item-inner > div {
  display: table-cell;
  vertical-align: middle;
}

.ui-el-help {
  font-weight: 400;
  color: var(--theme);
}

.ui-idiomas {
  position: absolute;
  right: 0;
  bottom: 0;
}
.ui-idioma-login {
  right: 145px;
}

.ui-idiomas > div {
  cursor: pointer;
  display: inline-flex;
  padding: 10px;
}

.ui-idiomas img {
  width: 40px;
  filter: grayscale(100%);
}

.ui-idioma.ui-item-select {
  border: 0 !important;
}

.ui-idioma.ui-item-select img {
  filter: grayscale(0);
}

.ui-idioma-item {
  display: table;
}

.ui-idioma-item > div {
  display: table-cell;
  vertical-align: middle;
}

.ui-idioma-item img {
  width: 40px;
  padding-right: 10px;
}

#drop-idiomas img.avatar {
  border-radius: 0;
  padding-left: 10px;
  width: 40px;
  height: auto;
}

.ui-el-type-entity .ui-dialog-data .ui-el-type-list .ui-el-label {
  display: none;
}

.ui-dialog-close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  padding: 10px 20px;
}

.menu-lateral {
  position: absolute;
  left: 0;
  right: -10px;
  top: 90px;
  bottom: 0;
  overflow: auto;
  z-index: 999999;
}

.ui-layout-tab-menu .ui-grid-row {
  display: table-cell;
  color: #555555;
  border-bottom: 5px solid var(--theme);
}

.ui-layout-tab-menu .ui-search-data {
  border: 0;
}
.ui-layout-tab-menu .ui-grid-col {
  font-weight: 400;
  text-transform: uppercase;
  padding: 15px;
  font-size: 18px;
  border: 0;
}
.ui-tab-menu-sel.ui-grid-row {
  border-color: #a3c5ff;
}

.ui-el-type-card {
  background: #fff;
  color: #555;
  margin: 10px;
  border: 1px solid #dedede;
  margin-bottom: 15px;
  box-shadow: 2px 2px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.ui-el-type-card > .ui-el-item-inner {
  padding: 15px;
}

.ui-el-type-card > .ui-el-label {
  font-weight: 500;
  padding-left: 10px;
  border-bottom: 1px solid #dedede;
  padding: 15px;
}

.ui-main-menu .ui-grid-col.ui-col-label {
  font-weight: normal;
  font-size: 18px;
  color: #888;
}

.ui-el-type-legend > .ui-el-label {
  padding: 15px 0;
  font-size: 25px;
  color: #555;
}

.ui-consultor .ui-el-item-produtos > .ui-el-label {
  background: #fff;
  padding: 15px;
}

.ui-consultor .ui-el-item-produtos {
  border: 1px solid #dedede;
  border-radius: 5px;
  overflow: hidden;
}

.ui-consultor .ui-el-item-produtos .ui-search-data {
  border: 0;
}

.ui-col-avatar {
  width: 80px;
  text-align: center;
  vertical-align: middle;
  margin: auto;
}

.ui-lista-consultores .ui-grid-col {
  border: 0;
}

.ui-lista-consultores .ui-el-type-card > .ui-el-item-inner > div {
  display: table-cell;
  vertical-align: middle;
}

.ui-lista-campanhas {
  vertical-align: middle;
}

.campanha-list-banner {
  height: 100%;
}

.campanha-list-banner img {
  margin-left: 50px !important;
  height: 120px !important;
  max-height: 120px !important;
  width: auto !important;
  object-fit: contain !important;
}

.ui-grid-col.ui-col-card div {
  margin: 0;
}

.ui-consultor .ui-grid-row .ui-el-type-card {
  border-left: 8px solid var(--theme);
}

.ui-lista-consultores .ui-search-data {
  border: 0;
}
.ui-grid-col.ui-col-card {
  padding: 5px 10px;
}

.noppading,
.noppading * {
  padding: 0;
}
.nomargin {
  margin: 0;
}
.nomargin-right {
  margin-right: 0;
}
.nomargin-left {
  margin-left: 0;
}

.ui-rm-margin-card > div > div {
  margin-left: 0;
  margin-right: 0;
}

.ui-lista-consultores .ui-el-type-card > .ui-el-item-inner {
  display: table;
  width: 100%;
}

.ui-lista-consultores .ui-el-item-avatar,
.ui-lista-consultores .ui-el-item-remove {
  width: 80px;
}

.ui-consultor .ui-col-remove {
  background: var(--theme);
  color: #fff;
}

.ui-el-type-checkbox .ui-el-item-inner {
  padding-right: 20px;
}

.ui-consultor .ui-el-item-botoes button {
  float: right;
  background: green;
  border-color: green;
}

.ui-inline-child > .ui-el-item-inner > div {
  display: inline-flex;
  vertical-align: middle;
  padding-right: 15px;
}

.ui-view-entrevista .ui-el-type-legend {
  background: #fff;
  margin-bottom: 15px;
}

.ui-view-entrevista .ui-el-type-legend > .ui-el-label {
  background: var(--theme);
  color: #ffff;
  padding-left: 15px;
}

.ui-view-entrevista .ui-el-type-legend > .ui-el-item-inner {
  padding: 15px;
}
.ui-view-entrevista .ui-el-type-form > .ui-el-label {
  text-align: center;
  font-size: 30px;
  padding: 15px;
  font-weight: 400;
}

.view-data.ui-view-full {
  margin: 0;
  padding: 0;
}

.ui-el-type-checklist li {
  cursor: pointer;
}

.ui-el-item-bottom {
  padding: 15px !important;
}

.ui-el-type-card > .ui-el-item-bottom .ui-el-type-button {
  display: inline-flex;
}

.ui-el-type-card .ui-el-item-bottom {
  text-align: right;
}

.ui-el-item-bloco_carta .ui-el-type-card > .ui-el-item-inner {
  padding: 15px;
  background: #f5f5f5;
}

.ui-catalogo-carousel:empty {
  display: none !important;
}

.ui-catalogo-carousel {
  height: 540px;
}

.ui-layout-card-row .ui-grid-col {
  border: 0;
}

.ui-layout-card-row .ui-search-data {
  border: 0;
}

.ui-layout-card-row .ui-grid-col > div {
  background: #fff;
  padding: 25px;
  border-radius: 3px;
  font-size: 20px;
  border-left: 6px solid currentColor;
}

.ui-layout-grid > .ui-el-item-inner {
  display: table;
  width: 100%;
}

.ui-layout-grid > .ui-el-item-inner > div {
  display: table-cell;
}

.ui-layout-card-row .ui-row-even,
.ui-layout-tab-menu .ui-row-even {
  background: transparent;
}

.ui-el-item-bloco_carta .ui-el-type-card > .ui-el-item-inner .ui-el-label {
  background: #f5f5f5;
}

.ui-lista-consultores .ui-grid-row {
  background: transparent;
}

nav .ui-tab-sel,
nav .ui-tab-sel * {
  color: var(--theme) !important;
}

.ui-fluxo-row > div {
  height: 55px;
  border: 0;
}

.ui-catalogo-top .ui-el-type-complete {
  width: 350px;
}

.ui-catalogo-top .ui-el-type-complete * {
  border-color: var(--theme) !important;
  line-height: 10px;
}

.ui-catalogo-top .ui-el-type-complete .MuiInputBase-root {
  height: 45px;
}

.ui-catalogo-top .ui-el-type-complete input {
  margin-top: -5px;
}

.ui-el-item-informacoes .ui-el-type-card {
  margin: 0;
  background: transparent;
  border: 0;
  box-shadow: none;
}

.ui-el-item-informacoes .ui-el-type-card .ui-el-label {
  border: 0;
  border-bottom: 1px solid #dedede;
  margin: 0 15px;
  padding-left: 0;
  padding-bottom: 5px;
}

.ui-el-type-date table,
.ui-el-type-datetime table {
  width: 100%;
}

.row {
  padding-left: 0 !important;
  margin-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.ui-row-group > div {
  text-align: center;
}

.ui-serch-card .ui-search {
  background: #fff;
  padding: 5px 15px 15px 15px;
  box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.75);
}

.MuiList-padding {
  padding: 10px;
}

.ui-filter-apply {
  text-align: right;
  display: block;
}

.ui-el-item-abrir {
  width: 80px;
  text-align: center;
}

.ui-grid-meus-pgtos {
  margin-top: 15px;
  border-spacing: 0 10px;
}

.ui-grid-meus-pgtos .ui-col {
  width: auto;
  vertical-align: middle;
  background: #fff;
}

.ui-grid-meus-pgtos .ui-col {
  display: table-cell;
}

.ui-grid-pgtos .ui-tabs button {
  width: 100%;
}

.ui-grid-meus-pgtos .ui-col.ui-pgto-detalhe {
  background: #dedede;
}

.ui-grid-meus-pgtos .ui-col.ui-pgto-valor {
  width: 200px;
}

.ui-grid-pgtos .ui-col.ui-item-pgto {
  width: 25px;
  text-align: center;
  vertical-align: middle;
  line-height: 80px;
  color: #fff;
  background: #04b90a;
  font-weight: 400;
  border-radius: 3px 0 0 3px;
}

.ui-pagamento .ui-content {
  font-size: 18px;
  background: #f5f5f5;
}

.ui-escolha-pgto button {
  height: 55px;
  margin-top: 5px;
  width: 120px;
}

.ui-grid-meus-pgtos > div {
  vertical-align: middle;
}

.ui-grid-pgtos .ui-col.ui-item-pgto-1 {
  background: #ffa700;
}

.ui-grid-pgtos .ui-col.ui-item-pgto-2 {
  background: #0a4c96;
}

.ui-grid-meus-pgtos .ui-tabs {
  opacity: 0.5;
}

.ui-grid-meus-pgtos .ui-tabs .ui-item-select {
  border-bottom-color: green !important;
}

.ui-grid-meus-pgtos .ui-el-item-confirmar button {
  text-align: right;
  float: right;
}

.ui-layout-column .ui-grid-row {
  display: table-cell;
}

.ui-grid-row img {
  max-width: 100%;
}

.ui-dash-eventos img {
  max-height: 200px;
}

.ui-dash-eventos .ui-el-item-anexos {
  text-align: center;
}

.ui-dash-eventos .ui-grid-col,
.ui-dash-eventos .ui-col {
  border: 0 !important;
  margin: 0;
}

.ui-dash-eventos .ui-el-item-conteudo {
  height: 100px;
  overflow: hidden;
}

.ui-dash-eventos .ui-el-item-conteudo > div,
.ui-dash-eventos .ui-el-item-titulo > div {
  padding-left: 10px;
  padding-right: 10px;
}

.ui-chart-noticias .ui-chart-data {
  background: #fff;
  text-align: center;
}
.ui-chart-act {
  cursor: pointer;
}

.ui-item-noticias > .ui-col-element {
  margin: 10px;
  background: #fff;
  padding: 15px;
  box-shadow: 2px 2px 10px -5px rgba(0, 0, 0, 0.2);
}

.ui-list-noticias .ui-search-data {
  border: 0;
  background: transparent;
}

.ui-item-noticias {
  display: inline-block !important;
  background: transparent;
}

.ui-item-noticias > .ui-col-element {
  margin: 10px;
  background: #fff;
  padding: 15px;
  box-shadow: 2px 2px 10px -5px rgba(0, 0, 0, 0.2);
  height: 100%;
}

.ui-item-noticias {
  background: transparent;
  width: 100%;
}

.ui-view-noticias .ui-list-noticias .ui-el-label {
  padding-left: 10px;
  text-align: center;
  font-size: 25px;
}

.ui-item-noticias .ui-el-item-conteudo {
  height: 10%;
  overflow: hidden;
}

.ui-destaque span {
  background: var(--primary);
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 10px;
}

.ui-actions {
  text-align: right;
}

.ui-table {
  width: 100%;
}
.ui-table-col {
  padding: 10px 5px;
}

.ui-row-group td {
  border: 1px solid #dedede;
  text-align: center;
  background: #f7f7d6;
  border-top: 0;
}

.ui-view-relatorio .ui-search {
  background: #fff;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 2px 2px 10px -5px rgba(0, 0, 0, 0.2);
}

.ui-row-group td:last-child {
  border-right: 0;
}

.ui-row-group td:first-child {
  border-left: 0;
}

.ui-el-label {
  position: relative;
}

.ui-el-label .ui-el-item-actions {
  float: right;
}

.ui-consultor .ui-lista-consultores .ui-el-item-remove {
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 99999;
}

.ui-consultor .ui-el-type-card {
  position: relative;
}

.ui-entrevistas .ui-el-item-dh_registro {
  text-align: right;
}

.ui-el-item-info_pontos_a,
.ui-el-item-info_pontos_b {
  color: var(--contrast);
  font-size: 20px;
  text-align: left;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 10px;
}

.ui-view-entrevista .ui-disabled * {
  cursor: auto !important;
}

.ui-range-label {
  font-size: 11px;
  margin-top: 5px;
  margin-left: 15px;
  color: var(--theme);
  position: absolute;
}

.ui-date-range .MuiTextField-root > label {
  top: -4px;
}

.ui-date-range label.Mui-focused {
  top: 3px;
}

#drop-idiomas {
  padding: 0;
  margin-left: 5px;
}

.ui-chart-data .ui-spinner-loading {
  text-align: center;
  font-size: 30px;
  color: var(--theme);
}

.MuiButton-containedPrimary {
  background-color: var(--theme) !important;
}

.menu-lateral .ui-grid-row .ui-el-item-label {
  width: 80px;
  display: block;
  font-size: 14px;
  line-height: 20px;
}
.ui-layout-gallery .ui-grid-row {
  display: none;
}

.ui-layout-gallery .ui-grid-row.ui-selected-row {
  display: table-row;
}

.ui-consultor .ui-el-type-file .ui-el-label {
  font-weight: 400;
  font-size: 22px;
  padding-bottom: 10px;
}

.ui-el-type-editor .ui-el-item-inner {
  display: block;
  min-height: 100px;
  margin-bottom: 20px;
}

.ui-promocao-empty {
  text-align: center;
  padding-top: 50px;
}

.ui-layout-line-card {
  background: transparent;
}

.ui-layout-line-card .ui-grid-col {
  background: #fff;
  border-bottom: 0;
  padding: 20px;
}

.ui-layout-line-card .ui-grid {
  border-spacing: 0 10px;
}

.ui-layout-line-card .ui-search-data {
  border: 0;
}

.ui-layout-line-card .ui-grid-col:first-child {
  border-left: 8px solid var(--theme);
  border-radius: 3px;
}

.ui-layout-line-card .ui-grid-col i {
  font-size: 25px;
}

.ui-grid-col > .ui-el-type-datetime {
  text-align: right;
}

.ui-dialog-loading .MuiPaper-root {
  background: transparent;
}

.ui-dialog-loading * {
  box-shadow: none !important;
}

.ui-el-item-edit .ui-layout-group {
  background: transparent;
  border: 0;
  margin: 0;
  box-shadow: none;
  padding: 0;
}

.ui-el-item-edit .ui-layout-group > .ui-el-item-inner > .ui-el-type-card {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}

.ui-el-type-youtube {
  text-align: center;
}
.ui-el-type-youtube iframe {
  margin-top: 20px;
}

.ui-grid-dialog-button {
  height: 44px;
}

.carousel .slide {
  background: #fff !important;
}

.ui-grid-noticias .carousel.carousel-slider {
  max-height: 340px;
}

.ui-col-type-date,
.ui-col-type-datetime {
  text-align: right;
}

.ui-view-carrinho .ui-content .ui-shadow {
  border-radius: 5px;
  width: 100%;
  border: 1px solid #cccccc;
}

.ui-pgto-confirmacao {
  background: #fff;
  border: 1px solid #dedede;
}

.ui-col.ui-parcelas {
  display: table-cell;
  width: auto;
}

.ui-col.ui-pgto-cartao {
  display: table-cell;
}

.ui-el-item-acoesPgto button {
  margin-left: 15px;
  min-width: 130px;
}

.ui-evt-passado {
  color: #e85f2c;
}

.ui-evt-mes {
  color: #459a45;
}

.ui-evt-futuro {
  color: #2896e2;
}

.ui-catalogo-top > div {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  width: 30%;
}

.ui-catalogo-top {
  display: table;
  width: 100%;
}

.ui-catalogo-search {
  width: 300px;
}

.ui-catalogo-acts {
  width: 120px;
}

.ui-catalogo-actions > .ui-el-item-inner {
  display: table;
  width: 100%;
}

.ui-catalogo-actions > .ui-el-item-inner > div {
  width: auto !important;
  display: table-cell !important;
  text-align: center;
  vertical-align: middle;
}

.ui-catalogo-data .ui-search-data {
  overflow: visible;
}

.PreviewList-smallPreviewImg-306 {
  height: 75px;
}

.ui-catalogo-carousel,
.ui-catalogo-data {
  max-width: 1400px;
  margin: auto;
}

.ui-catalogo-data {
  max-width: 1200px;
}

.ui-catalogo .ui-row-card .ui-col-element {
  background: transparent;
  border: 1px solid #dedede;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px;
  position: relative;
}

.ui-selected.ui-el-item-favorito * {
  color: var(--contrast) !important;
}

.ui-selected.ui-el-item-favorito button {
  border: 1px solid var(--contrast);
}

.ui-catalogo-content .carousel-slider {
  height: 450px;
}

.ui-catalogo-content .carousel-root ul.thumbs {
  text-align: center;
  margin: 10px;
}

.ui-el-item-comprar i {
  margin-right: 15px;
}

.ui-button-icon.ui-button-label i {
  margin-right: 15px;
}

.ui-el-item-cores .ui-el-label {
  font-size: 14px;
}

.ui-carousel-small .ui-grid-row .ui-grid-col > div {
  border: 1px solid #dedede;
  height: 60px;
  position: relative;
  overflow: hidden;
  background: transparent;
  width: 60px;
  text-align: center;
  border-radius: 5px;
}

.ui-carousel-small .ui-grid-row .ui-grid-col {
  padding: 1px;
  width: 65px;
}

.ui-carousel-small .ui-grid-row .ui-grid-col > div * {
  margin: auto;
  vertical-align: middle;
}

.ui-pagamento .ui-content {
  font-size: 18px;
}

.ui-pagamento .ui-topo {
  text-transform: uppercase;
}

.ui-transparent {
  background: transparent;
}

.MuiAutocomplete-root i {
  padding-right: 5px;
  font-size: 18px;
}

.MuiAutocomplete-popupIndicatorOpen i {
  transform: rotate(180deg);
}

.ui-catalogo {
  background: #fff;
}

.MuiButton-root {
  height: 40px !important;
}

.carousel .slider .slide img {
  max-height: 450px;
  max-width: 100%;
  width: auto !important;
}

.ui-el-gallery .ui-dialog-data {
  overflow: hidden;
  background: #fff;
  text-align: center;
  padding: 0;
}

.ui-el-gallery .ui-dialog-data img {
  text-align: center;
  height: 100%;
  margin: auto;
}

.ui-el-gallery .ui-dialog-data {
  top: 0;
}

.ui-el-gallery .ui-dialog-header {
  border: 0;
  z-index: 99999;
  position: absolute;
  right: 0;
  padding: 30px;
}

.ui-button-label {
  vertical-align: middle;
}

.ui-main-menu .ui-grid-row {
  background: transparent;
}

.ui-tag-destaque .ui-el-item-inner {
  background: var(--contrast);
}

.ui-chart-pontos {
  background: #fd7a49 !important;
}

.ui-el-item-social_midias .ui-grid,
.ui-el-item-social_midias .ui-grid-row,
.ui-el-item-social_midias .ui-grid-col {
  display: inline-flex;
  background: transparent;
  font-size: 30px;
}

.ui-el-item-social_midias i {
  color: var(--theme);
}

.ui-view-noticias .ui-grid-row .ui-grid-col,
.ui-view-noticias .ui-grid-row:hover .ui-grid-col,
.ui-view-noticias .ui-grid-row:hover .ui-grid-col * {
  background: transparent !important;
}

.ui-view-noticias .ui-el-item-right .ui-el-item-titulo {
  font-size: 30px;
  color: var(--theme);
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

.ui-noticias-main > .ui-col-element .carousel .slide {
  background: transparent !important;
}

.ui-view-noticias .ui-el-item-actions {
  float: left;
}

.ui-consultor .ui-col-header {
  background: transparent;
}

.ui-consultor .ui-el-item-tabs,
.ui-consultor .ui-el-item-tabs * {
  text-align: center;
}

.ui-consultor .ui-el-item-tabs .ui-grid-col {
  width: 100%;
  display: block;
}

@media (min-width: 800px) {
  .ui-el-item-logar {
    top: 20% !important;
    left: auto;
    margin-top: auto;
    right: 50px !important;
    padding-bottom: 3%;
    margin-top: 50px;
  }
}

.ui-el-item-inner img {
  max-width: 100%;
}

.ui-el-item-im_dim img {
  max-width: 300px;
  text-align: center;
  margin: auto;
  display: block;
}

.ui-el-item-acessorios > .ui-el-label,
.ui-el-item-especial > .ui-el-label {
  font-size: 20px;
  text-align: center;
  color: var(--theme);
}

.ui-el-item-logo_ngs {
  position: fixed;
  top: calc(20% + 100px);
  max-width: 40% !important;
  left: 0px;
  margin-left: 40px;
}

@media (max-width: 800px) {
  .ui-el-item-logo_ngs {
    display: none;
  }
}

.ui-el-item-logar input {
  text-align: center;
}

.ui-el-type-list.ui-layout-transparent,
.ui-el-type-list.ui-layout-transparent * {
  background: transparent;
  border: 0;
}

.ui-el-type-list.ui-layout-transparent .ui-grid-row {
  display: inline-block;
}

.ui-el-type-list.ui-layout-transparent {
  text-align: center;
}

.ui-el-item-menu_overlay a {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  opacity: 0.5;
  z-index: 999998;
  display: block;
}

.ui-el-item-idiomas .ui-grid-row img {
  width: 50px;
}

.ui-el-item-idiomas .ui-grid-row.ui-click:hover .ui-grid-col {
  background: transparent !important;
}

.ui-el-item-regras * {
  border: 0;
}

.ui-el-item-regras > .ui-el-item-inner {
  text-align: center;
  margin: auto;
  margin-top: 10px;
  padding: 2px;
  border-radius: 5px;
}

.ui-fase.ui-grid-row {
  display: table-cell;
  background: transparent !important;
}

.ui-el-item-regras {
  text-align: center;
}

.ui-fase > div {
  border-radius: 5px;
  margin: 3px;
  padding: 3px;
  display: block;
  color: #fff;
}

.ui-fase-err > div {
  background: var(--contrast);
}

.ui-fase-ok > div {
  background: var(--success);
}

.ui-pgto-close {
  text-align: right;
  font-size: 30px;
}
.ui-catalogo-sub .ui-catalogo-item {
  display: block;
}

.ui-catalogo-even > .ui-catalogo-label {
  color: var(--contrast);
}

.ui-catalogo-odd > .ui-catalogo-label {
  color: var(--theme);
}

.ui-catalogo-item-0.ui-catalogo-even > .ui-catalogo-label {
  color: #555;
}

.ui-files-preview img {
  width: 80px;
}

.ui-files-preview td {
  padding: 10px;
  border-bottom: 1px solid #dedede;
}

.ui-files-preview {
  border: 1px solid #dedede;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
}

.ui-del-file {
  cursor: pointer;
  text-align: center;
  width: 80px;
}

.ui-files-prev {
  width: 80px;
}

.ui-files-prev i {
  font-size: 30px;
}

.ui-catalogo-search .ui-el-item-comandos > div,
.ui-catalogo-search > div > div {
  display: inline-flex;
}
.ui-catalogo-search .ui-el-item-comandos > div {
  text-align: center;
  align-items: center;
  height: 100%;
}

.ui-chart .ui-chart {
  box-shadow: none;
}

.ui-layout-clear .ui-grid-row,
.ui-layout-clear .ui-grid-col {
  background: transparent;
  border: 0;
}

.WhiteColor,
.WhiteColor * {
  color: #fff !important;
}

.ui-view-informativos .ui-el-type-list > .ui-el-label {
  font-size: 25px;
}
.ui-view-informativos .ui-el-type-list .ui-col-detalhes {
  border-right: 1px solid #dedede;
  max-width: 60px;
}
.ui-bold {
  font-weight: bold;
}

.ui-search-groups .ui-search-data {
  display: table;
  width: 100%;
}

.ui-search-groups .ui-search-data > div {
  display: table-cell;
  vertical-align: top;
}

.ui-search-groups .ui-search-data-left,
.ui-search-groups .ui-search-data-left * {
  background: transparent;
  border: 0;
}

.ui-search-groups .ui-search-data-left {
  width: 350px;
}

.ui-pagamento .fa-check-circle {
  color: #429801;
}

.ui-google-preview iframe {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.ui-pagamentos-pendentes .ui-el-item-acoes > div > div {
  display: inline-flex;
  width: auto;
  padding-right: 15px;
}

.ui-el-type-link {
  cursor: pointer;
}

.ui-chart-pie .ui-chart-data,
.ui-chart-bar .ui-chart-data {
  overflow: hidden;
}

.ui-search-data-left * {
  background: transparent !important;
  border: 0 !important;
}

.ui-el-type-editor.ui-disabled .ui-el-item-inner {
  border: 1px solid #888;
  border-radius: 3px;
  padding: 10px;
  opacity: 0.7;
}

.ui-view-informativos .ui-search-data-right .ui-row-odd {
  background: #fff;
}

.ui-component .ui-col-element {
  padding: 10px;
}

.ui-grid-card .ui-grid-row > div {
  background: #fff;
}

.ui-grid-card .ui-search-data {
  border: 0;
}

.ui-border-left.ui-grid-row > div:first-child {
  border-left: 5px solid #888;
}

.ui-border-green > div:first-child {
  border-left-color: green !important;
}

.ui-grid-card .ui-grid {
  border-spacing: 0 15px;
}

.ui-grid-group .ui-grid-row {
  font-weight: bold;
}

.ui-grid-group .ui-grid-row .ui-grid-col {
  padding: 0;
}

.ui-grid-group .ui-grid-row .ui-col-value > div {
  color: #fff;
  background: #888 !important;
  text-align: center;
  margin-right: 15px;
  border-radius: 5px;
  font-size: 12px;
  padding: 2px;
}
.ui-border-red > div:first-child {
  border-left-color: #dc3545 !important;
}

.ui-group-filter-total {
  background: #888 !important;
  display: inline-block;
  padding: 5px;
  font-size: 10px;
  border-radius: 3px;
  color: #fff;
  margin-left: 10px;
  width: 25px;
  text-align: center;
  height: 25px;
}

li.ui-group-filter-item {
  padding-bottom: 5px;
  vertical-align: middle;
  cursor: pointer;
  padding-top: 5px;
}

.ui-grid-fix-selected .ui-grid-sub {
  background: transparent;
  padding: 0;
}

.ui-el-type-editor .ui-el-label {
  padding-left: 0 !important;
  margin-left: 0;
}

.ui-grid-fix-selected .ui-grid-row .ui-col-info {
  width: 200px;
}
.jodit-toolbar__box {
  width: 100% !important;
  max-width: 100% !important;
}

.ui-view-eventos .ui-grid-col.ui-col-info {
  min-width: 190px;
}

.ui-resumo-minhas-formas {
  margin-bottom: 20px;
}

.ui-row-agg {
  font-weight: bold;
}

.ui-view.ui-view-entrevista .ui-el-item-dh_entrevista {
  font-weight: bold;
  background: #fff;
  padding: 10px;
}

.ui-filter-item .ui-el-input,
.ui-filter-item .ui-el-type-date,
.ui-filter-item .ui-el-type-datetime {
  position: relative;
}
.ui-filter-item .ui-el-type-date,
.ui-filter-item .ui-el-type-datetime {
  padding: 0 !important;
}

.ui-filter-item .ui-el-type-complete,
.ui-filter-item .ui-el-type-select,
.ui-filter-item .ui-el-input {
  padding-top: 1px !important;
}

.ui-view-report > .ui-el-type-list > .ui-el-item-inner {
  background: #fff;
  padding: 0 10px 10px 10px;
  border: 1px solid #dedede;
  box-shadow: 2px 2px 10px -5px rgba(0, 0, 0, 0.2);
}

.ui-el-item-acoes_carrinho {
  text-align: right;
}

.ui-el-item-acoes_carrinho .ui-el-type-button {
  margin-left: 10px;
}

.ui-layout-tab-menu .ui-grid-row.ui-row-odd {
  background: transparent;
}

.user-filial {
  position: fixed;
  right: 20px;
  z-index: 999;
  display: block;
  top: 70px;
  font-weight: 500;
  box-shadow: 2px 2px 10px -5px rgb(0 0 0 / 20%);
  border-radius: 5px;
  background: var(--theme);
  padding: 2px 10px;
  color: #fff;
}

.user-name {
  padding-left: 15px;
  padding-right: 10px;
  color: var(--theme);
}

.MuiDialogActions-root button {
  min-width: 150px;
  margin-left: 10px;
  margin-right: 10px;
}

ul.ui-itens-acessorios {
  margin-top: 10px;
  border: 1px dashed;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 5px;
}

.ui-el-item-especial .ui-el-type-checkbox > div {
  border: 0 !important;
}

.ui-el-item-travesseiro label,
.ui-el-item-redondo label {
  padding-left: 0;
  margin-left: 0;
  font-weight: bold !important;
}

.ui-el-item-travesseiro,
.ui-el-item-redondo *,
.ui-el-item-redondo,
.ui-el-item-redondo * {
  font-size: 20px !important;
  font-weight: 400;
}
.ui-dlg-escolha-filial .MuiPaper-root {
  width: 500px;
}

.ui-dlg-escolha-filial .MuiPaper-root button {
  margin-bottom: 20px;
}

.ui-dlg-escolha-filial .MuiDialogTitle-root {
  font-size: 30px !important;
  padding-right: 15px;
}

.ui-dlg-escolha-filial .ui-el-item-title {
  font-size: 25px;
  color: green;
  font-weight: 400;
  padding-top: 15px;
  margin-bottom: 20px;
}

.ui-dlg-escolha-filial .ui-el-item-info {
  color: #555;
  padding-bottom: 20px;
}
.ui-dlg-escolha-filial .ui-el-item-title i {
  padding-right: 15px;
}

.ui-info-pgto-cart {
  font-weight: normal;
  color: orangered;
  padding-left: 20px;
}

.ui-info-pgto-cart i {
  padding-right: 15px;
  font-size: 30px;
}
.ui-cfg-acessorios .ui-el-item-totalizador {
  font-weight: bold;
  font-size: 24px;
  text-align: right;
  padding: 10px 0;
}

.ui-el-item-indisponivel {
  text-align: left;
  font-weight: bold;
  color: red;
  padding: 10px 0;
  font-size: 26px;
}

.ui-card-produto .ui-el-item-indisponivel {
  padding: 10px;
  font-size: 20px;
}

.ui-meus-pedidos .ui-el-item-anotacoes {
  background: #fff;
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid #dedede;
  border-radius: 2px;
}

.ui-meus-pedidos .ui-el-item-anotacoes .ui-col-header {
  background: #dedede;
}

.ui-meus-pedidos .ui-el-item-anotacoes .ui-grid-col {
  border-bottom: 1px solid #dedede;
  color: #000 !important;
}
.ui-total-pagar {
  font-size: 26px;
}

.ui-total-pagar .ui-small-label {
  font-weight: 500;
  font-size: 15px;
}

.ui-parc-tot-spv {
  font-size: 12px;
  color: #fd7e14;
  padding-left: 15px;
}

.ui-view-ui-contratos .ui-el-item-detail {
  padding-left: 15px;
  font-size: 20px;
  font-weight: 500;
}

.ui-el-item-contratos {
  background: #fff;
  margin: 10px;
  border: 1px solid #dedede;
  border-radius: 5px;
}

.ui-el-item-contratos .ui-search-left {
  padding-left: 10px;
}

.noborder {
  border: 0 !important;
}

.ui-el-type-button.ui-aviso-chegada button {
  width: 100%;
  margin-bottom: 10px;
  background: #49b149 !important;
}

.ui-col-type-action {
  background: var(--blue) !important;
  color: #fff !important;
  font-weight: 500;
  font-size: 12px;
}

.ui-col-type-action * {
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.ui-col-act.ui-disabled {
  background: #888888 !important;
  opacity: 0.7;
  border-left: 1px solid #fff;
}

.ui-disabled {
  cursor: not-allowed !important;
}

.ui-el-item-ou * {
  font-weight: bold;
  font-size: 16px !important;
}

.ui-el-item-anotacoes .ui-col-usuario {
  width: 100px;
}

.ui-col-type-datetime,
.ui-col-type-date {
  width: 100px;
}

.mobile-Label-m {
  display: none;
  font-weight: bold;
  font-size: 17px;
}

.anotacao-pagamento {
  padding-left: 15px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.add-spinn-classe-d {
  border-radius: 0 19px 19px 0 !important;
  right: -10px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.add-spinn-classe-e {
  border-radius: 19px 0 0 19px !important;
  left: -10px;
}

.label-qtd {
  width: 0;
}

.ui-layout-card-row .ui-grid-row.ui-row-odd {
  background: transparent !important;
}

.ui-previsao-frete {
  font-size: 12px;
  text-transform: uppercase;
  padding-right: 20px;
}

#qualificacao_dash {
  white-space: nowrap;
}

.ui-disabled button {
  pointer-events: none;
  border: 0 !important;
  color: #888 !important;
  opacity: 0.8;
}

.ui-grid-col.ui-col-btDetalhe.ui-col-act.ui-col-header.ui-col-type-action.ui-click {
  background: transparent !important;
}

.user-filial {
  transition: 0.2s;
}

.user-filial-hv:hover {
  padding: 5px 10px !important;
  transition: 0.2s;
  cursor: pointer;
}

.ui-el-type-select .MuiFormControl-root {
  width: calc(100% - 10px) !important;
}

.ui-catalogo-nav {
  display: flex;
  justify-content: center;
}

.top-cabecalho {
  box-shadow: 0px 2px 15px -12px rgb(0 0 0 / 75%);
  background-color: white;
  position: fixed;
  top: 65px;
  z-index: 99;
  width: 94.4%;
}

@media (max-width: 1080px) {
  .top-cabecalho .cabecalho-ranking {
    position: inherit;
    width: 100%;
  }

  .ui-catalogo-content {
    margin-top: 100px;
  }
}

.catalogo-top-classe {
  box-shadow: 0 0 0 transparent !important;
  padding: 10px 0;
}

.logo-classe {
  display: flex !important;
  justify-content: center;
  width: 100%;
}

.carrinho-classe button {
  height: 50px;
  width: 215px;
  background-color: var(--redBtnCart);
  border-radius: 35px !important;
  border: 0;
  font-family: "Montserrat", sans-serif;
}

.carrinho-classe div,
.logo-classe div {
  display: flex;
  justify-content: center;
  padding-right: 10px;
}

.carrinho-classe a,
.carrinho-classe a:hover {
  padding: 5px 10px 5px 0;
  color: #fc0101;
}

.logo-classe {
  padding: 12px 17px;
  padding-left: 50%;
  padding-right: 50%;
}

.ui-catalogo-nav {
  font-family: "Montserrat", sans-serif;
}

.carrinho-classe button:hover {
  background-color: #777777 !important;
}

.cart-ico-classe {
  margin: -10px 15px 0 0;
}

.cart-label-classe {
  margin: 10px 0 0 -10px;
}

.homeIcon {
  width: 30px;
  margin: 3px;
}

.fav-classe {
  margin-right: 5px;
}

.ui-catalogo-top .ui-el-type-complete * {
  border: 0;
}

.ui-catalogo-top .MuiTextField-root {
  background-color: #f3f3f3;
  border-radius: 30px;
}

.ui-catalogo-top .fa-search {
  color: #fc0101;
}

.ui-contratos-publico .ui-el-label {
  font-weight: bold;
  padding: 10px;
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
}

.ui-el-type-select .MuiInputLabel-outlined:not(.Mui-focused) {
  transform: translate(14px, -6px) scale(1);
  background: #fff;
  font-size: 12px;
}

.ui-dash-eventos .ui-grid-row {
  position: relative;
}

.ui-dash-eventos .ui-grid-row > div {
  position: initial;
}

.ui-dash-eventos .ui-el-item-ler {
  position: absolute;
  bottom: 0px;
  background: #fff;
  border-radius: 30px;
  opacity: 0.8;
}

.ui-bloco-saldos .ui-chart.ui-chart-header .ui-col {
  margin: 0 !important;
  padding: 5px !important;
}

.ui-bloco-saldos .ui-chart.ui-chart-header {
  margin-top: -5px;
}

.classe-preco-original-sup .ui-el-item-inner {
  margin: 50px 0 -50px;
}

.sun-classe div svg g g g g g g g g g g g g g g g {
  transform: translate(0px, 0px);
}

.sun-classe div svg g g g g g g g g g g g g g g g:hover {
  fill: rgb(0, 64, 236, 1);
}

.ajust-descricao {
  word-break: break-word;
}

.ui-erro-frete {
  color: #c7344d;
}

.agglistClass .ui-col-remove {
  background: white !important;
}

.ui-meus-comprovantes .ui-el-label {
  font-weight: bold;
  margin-top: 30px;
  border-bottom: 1px solid;
}

.ui-meus-comprovantes {
  margin-bottom: 30px;
}

.ui-view-cliente-consultor .ui-el-item-botoes .ui-el-item {
  display: inline !important;
}

.ui-view-cliente-consultor .ui-el-item-botoes .ui-el-item button {
  margin-left: 15px;
}

.ui-filter-options {
  text-align: right;
}

.ui-filter-options > div {
  display: inline-block;
  margin-left: 20px;
}

.buttonEvoluir {
  display: flex;
  justify-content: flex-end;
}

.buttonEvoluir button {
  background-color: rgb(161, 161, 161) !important;
}

.ui-fase-class-ajust {
  width: 50%;
}

.btn-eye {
  padding: 0 0 !important;
  display: inline-flex !important;
  border: 0 !important;
  background-color: transparent;
  color: white;
  margin-left: 10px;
  font-size: 20px;
}

.ui-dashboard-class .ui-chart-label {
  font-size: 14px !important;
}
.ui-dashboard-class .ui-chart-destaque img {
  margin-top: 0px;
}

@media (max-width: 1591px) and (min-width: 1281px) {
  .ui-dashboard-class .ui-chart-label {
    font-size: 10px !important;
  }

  .ui-dashboard-class #valor_creditos {
    white-space: nowrap;
  }

  .ui-dashboard-class .ui-chart-valor,
  .ui-dashboard-class .ui-chart-destaque {
    font-size: 12px !important;
    padding: 5px 10px;
    height: 33px;
  }
}

.ui-el-avatar-hover .primeiroAvatar {
  opacity: 1;
  transition: 0.3s;
}

.ui-el-avatar-hover .segundoAvatar {
  margin-top: -208px !important;
  opacity: 0;
  transition: 0.3s;
}

.ui-el-avatar-hover:hover .primeiroAvatar {
  display: inline-block;
  transition: 0.3s;
  opacity: 0;
}

.ui-el-avatar-hover:hover .segundoAvatar {
  display: inline-block;
  transition: 0.3s;
  opacity: 1;
}

.zoomMaster img {
  width: 1000px;
  max-width: initial;
  position: absolute;
}

.zoomMaster {
  position: absolute;
  z-index: 9999;
  top: 0px;
  left: 700px;
  width: 500px;
  height: 500px;
  overflow: hidden;
  display: none;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 10px;
  background-color: white;
}

.imageFirst:hover ~ .zoomMaster {
  display: block;
}

.imageFirst {
  cursor: zoom-in !important;
}

.ui-pop-up-geral {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99999;
}

.ui-pop-up {
  position: fixed;
  z-index: 999999;
  animation-name: popupOpacity;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.ui-pop-up img {
  animation-name: popupImg;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

@keyframes popupOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popupImg {
  0% {
    width: 0%;
  }
  30% {
    width: 105%;
  }
  65% {
    width: 98%;
  }
  100% {
    width: 100%;
  }
}

.ui-pop-up > div {
  height: 90%;
}

.ui-pop-up .close {
  position: absolute;
  z-index: 999999999;
  right: 10px;
  top: 10px;
}

.ui-pop-up-button {
  position: relative;
  z-index: 99999999;
  height: 100% !important;
}

.ui-pop-up-fundo {
  background-color: rgba(0, 0, 0, 0.233);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999999;
}

.ui-catalogo-content {
  width: calc(100% - 440px);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.ui-banner-lateral {
  position: absolute;
  margin-top: 180px;
  width: 200px;
  overflow: hidden;
  z-index: 9;
}
.ui-banner-principal {
  padding-left: 90px;
  padding-right: 90px;
}
.ui-banner-esquerda {
  left: 20px;
}

.ui-banner-direita {
  right: 20px;
}

@media (max-width: 1500px) {
  .ui-banner-lateral {
    display: none;
  }
  .ui-catalogo-content {
    width: 100%;
  }
}

.ui-catalogo {
  display: flex;
}
.ui-logo-catalogo {
  padding-right: 250px;
  height: 60px;
}
.ranking-list-banner {
  height: 100px !important;
  width: auto !important;
}

.cabecalho-ranking {
  box-shadow: 0px 2px 15px -12px rgb(0 0 0 / 75%);
  background-color: white;
  top: 65px;
  z-index: 99;
}

.banner-ranking {
  padding: 12px 17px;
  display: flex !important;
  justify-content: center;
}

.ui-layout-tab-menu .ui-grid-row > div {
  width: 100%;
  display: block;
}

.ui-escolha-pgto-uk {
  display: grid;
  width: 50%;
  height: 100%;
}

.ui-escolha-pgto-uk > div {
  display: table-cell;
  height: 49px;
}

.ui-grid-metodo-pgtos .ui-col {
  border-bottom: 4px solid #dedede;
  padding: 10px 20px !important;
  border-left: 1px solid #dedede;
}

.ui-grid-metodo-pgtos .ui-col:hover,
.ui-grid-metodo-pgtos .ui-col.ui-tab-sel {
  color: var(--theme);
  border-color: currentColor;
}

.ui-grid-metodo-pgtos .ui-col:hover {
  opacity: 0.6;
}
.ui-grid-pgtos.ui-grid-metodo-pgtos {
  border-radius: 0 !important;
  border: 0 !important;
}

.ui-view.ui-view-noticias > div {
  vertical-align: top;
}

.ui-el-type-list.ui-layout-button .ui-grid-row {
  display: inline-block;
  background: #fff;
  margin: 3px;
  border-color: var(--redBtnCart);
  color: #fff;
  border-radius: 5px;
}

.ui-el-type-list.ui-layout-button .ui-grid-row > div {
  color: #151111;
  border: 1px solid currentColor;
  border-color: rgb(109, 108, 108);
  border-radius: 4px;
}

.ui-el-type-list.ui-layout-button
  .ui-search-data
  .ui-grid-row:last-child
  .ui-grid-col {
  border-bottom: 1px solid currentColor !important;
}

.ui-el-type-list.ui-layout-button .ui-current-grade-item.ui-grid-row > div {
  background: #f02325;
  border-color: #f02325;
  color: #fff;
}

.ui-complete-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.ui-view.ui-view-blog {
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  bottom: 0;
}

div#viewer {
  position: absolute;
  left: 190px;
  right: 0;
  top: 0;
  bottom: 0;
}

.ui-ass-finish {
  background: var(--primary);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 10px;
  font-size: 50px;
  color: #fff;
  text-align: center;
  padding-top: 10%;
}
.ui-ass-commands {
  width: 200px;
  background: var(--primary);
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  padding: 10px;
}

.ui-ass-commands * {
  color: #fff;
}

.ui-ass-commands button {
  background: var(--contrast);
  width: calc(100% - 20px);
  margin-bottom: 10px;
  border-radius: 5px !important;
}

button.ui-ass-complete {
  background: var(--green);
}

.ui-ass-back {
  color: #ccc !important;
  font-size: 30px !important;
  text-decoration: underline;
}

.ui-btn-ass-back {
  background: #00b1ff !important;
  font-size: 17px !important;
}

.ui-col-type-number,
.ui-col-type-integer,
.ui-col-type-money {
  text-align: right;
}

.ui-el-item-acessorios .ui-grid {
  display: block;
}

.ui-el-item-acessorios .ui-grid .ui-grid-row {
  display: block;
}

.ui-el-item-acessorios .ui-grid-row .ui-grid-col.ui-col-observacao {
  display: block;
  width: 100%;
}

.credencial-card {
  z-index: 0;
  width: 544px;
  height: 1110px;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 79%;
}

.credencial-background-img {
  top: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
  align-self: center;
  z-index: 1;
}

.credencial-field {
  position: absolute;
  color: black;
  text-align: left;
  vertical-align: middle;
  width: 420px;
  height: 43px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  left: 8%;
  z-index: 2;
}

.credencial-avatar {
  position: absolute;
  top: 1.6%;
  left: 8%;
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
  z-index: 2;
}

.credencial-qrcode {
  position: absolute;
  color: black;
  top: 2%;
  left: 66%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  z-index: 2;
}

.credencial-nome-pessoa {
  top: 19.5%;
}

.credencial-tipo-socio {
  color: white !important;
  top: 22.3%;
  font-size: 15px !important;
  font-weight: 800 !important;
}

.credencial-nome-di {
  top: 29%;
}

.credencial-graduacao {
  top: 39%;
}

.credencial-cod-filial {
  top: 39%;
  left: 87.5% !important;
}

.credencial-codmun-di {
  top: 49.5%;
}

.credencial-codmun-presidente {
  top: 59.3%;
}

.ui-view.ui-view-external {
  height: 100%;
}

.pix-card {
  position: relative;
  display: flex !important;
  overflow: hidden;
  width: 100%;
  align-content: center;
  text-align: center;
}

.pix-header {
  top: 20px;
  font-size: 25px;
  font-weight: 1000;
  display: block;
  align-content: center;
  text-align: center;
}

@media (max-width: 768px) {
  .pix-header {
    font-size: 13px;
  }
}

.pix-instrucoes-qr-code-box {
  padding-top: 1px;
  display: block;
  width: 700px;
  margin: 0 auto;
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  .pix-instrucoes-qr-code-box {
    width: 400px;
  }
}

.pix-instrucoes-qr-code {
  top: 1px;
  font-size: 20px;
  font-weight: 1000;
  display: block;
  align-content: center;
  text-align: center;
}

@media (max-width: 768px) {
  .pix-instrucoes-qr-code {
    font-size: 10px;
  }
}

.pix-dados-pedido-box {
  padding-top: 30px;
  display: block;
  width: 300px;
  margin: 0 auto;
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  .pix-dados-pedido-box {
    width: 150px;
  }
}

.pix-dados-pedido-text {
  font-size: 20px;
  font-weight: 400;
  display: block;
  align-content: left;
  text-align: left;
}

@media (max-width: 768px) {
  .pix-dados-pedido-text {
    font-size: 10px;
  }
}

.pix-qrcode {
  top: 0px;
  float: center;
  position: relative;
  display: inline;
  text-align: center;
  width: 20%;
}

@media (max-width: 768px) {
  .pix-qrcode {
    width: 50%;
  }
}

.pix-instrucoes-copia-cola {
  top: 0px;
  font-size: 17px;
  font-weight: 200;
  align-content: left;
  text-align: left;
}

@media (max-width: 768px) {
  .pix-instrucoes-copia-cola {
    font-size: 9px;
  }
}

.pix-copia-cola-codigo {
  font-size: 15px;
  display: block;
  font-weight: 1000;
  float: center;
  text-align: center;
  word-break: break-all;
  white-space: normal;
}

@media (max-width: 768px) {
  .pix-copia-cola-codigo {
    font-size: 11px;
  }
}

.pix-copia-cola-button {
  display: block;
  align-content: center;
  text-align: center;
  float: center;
  margin: 20px;
  font-size: 15px;
}

@media (max-width: 768px) {
  .pix-copia-cola-button {
    font-size: 11px;
  }
}

.pix-confirmacao {
  margin: 20px;
  font-size: 30px;
  float: center !important;
  align-content: center !important;
  text-align: center !important;
}

.clonagem-cliente-card-existente {
  display: flex; /* Define um layout em linha */
  align-items: stretch; /* Garante que os elementos ocupem toda a altura */
  width: 100%; /* Ocupa toda a largura disponível */
  background-color: #f0fff0; /* Fundo verde claro */
  border: 1px solid #d4d4d4; /* Borda geral para separar visualmente */
  border-radius: 5px; /* Borda arredondada */
  overflow: hidden; /* Garante que nada ultrapasse os limites do div */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para um visual mais agradável */
  margin: 20px 0;
}

.clonagem-cliente-card-border-existente {
  background-color: #28a745; /* Verde escuro */
  width: 10px; /* Define a espessura da borda lateral */
  height: auto; /* Garante que acompanhe a altura do conteúdo */
}

.clonagem-cliente-card-content-existente {
  display: flex;
  align-items: center;
  padding: 15px; /* Espaçamento interno */
  flex: 1; /* O conteúdo ocupa o espaço restante */
}

.clonagem-cliente-card-icon-existente {
  color: #28a745; /* Cor verde para o ícone */
  margin-right: 15px; /* Espaçamento à direita do ícone */
  font-size: 24px; /* Tamanho do ícone */
}

.clonagem-cliente-card-text h3 {
  margin: 0;
  font-size: 18px; /* Tamanho do título */
  color: #334; /* Cor do texto */
}

/* .clonagem-cliente-card-text p {
  margin: 5px 0 0 0;
  font-size: 14px;
  color: #666;
} */

.clonagem-cliente-card-novo {
  display: flex;
  align-items: stretch;
  width: 100%;
  background-color: #FFF4CC;
  border: 1px solid #FF9800;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.clonagem-cliente-card-border-novo {
  background-color: #FF9800;
  width: 10px;
  height: auto;
}

.clonagem-cliente-card-content-novo {
  display: flex;
  align-items: start;
  padding: 15px;
  flex: 1;
  flex-direction: column;
}

.clonagem-cliente-card-icon-novo {
  color: #FF9800;
  margin-right: 15px;
  font-size: 24px;
}

.clonagem-cliente-card-text-novo, .clonagem-cliente-card-text-existente {
  display: flex;
  width: 100%;
  gap: 8px;
}

.clonagem-cliente-card-content-docto {
  margin-left: 30px;
  margin-right: 30px;
}

.ui-topo-dashboard {
  width: 100%;
  vertical-align: middle;
  text-align: left;
  font-size: 35px;
}

@media (max-width: 768px) {
  .ui-topo-dashboard {
    font-size: 25px;
  }
}

.ui-dashboard-resumo-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-collapse: collapse;
  align-items: center;
  font-size: 20px;
  vertical-align: middle;
}

.ui-dashboard-resumo-col {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  box-sizing: border-box;
}

.ui-dashboard-resumo-value-col {
  width: 20%;
  height: 70px;
  min-width: 150px;
  color: white;
  font-weight: 500;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.ui-dashboard-resumo-col .ui-dashboard-resumo-label-col {
  flex: 1;
  padding: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: left;
}

@media (max-width: 768px) {
  .ui-dashboard-resumo-row {
    flex-direction: column;
  }

  .ui-dashboard-resumo-col {
    width: 100%;
  }
}

.ui-dashboard-resumo-label-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ui-dashboard-resumo-showspv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  float: right !important;
  
  margin-left: auto;
}

.btn-eye {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #334;
  padding: 5px;
}

.btn-eye i {
  font-size: 30px;
}

.ui-dashboard-bg-blue {
  background-color: #00a8d0;
}

.ui-dashboard-bg-blue-dark {
  background-color: var(--theme) !important;
}